import { Injectable } from '@angular/core';
import { ApiClient } from '@concurrency/angular';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { PortalAdminModel } from '../responses/portal-admin.response';
import { User } from '../responses/user.response';
import { Auth0ApiClient } from '../api.client';

@Injectable()
export class UserClient extends Auth0ApiClient {
    protected baseUrl = `${environment.urls.subscriptionApi}/api/v1`;

    public read(): Observable<User> {
        return this.get(`User`);
    }
    public GetPortalAdmins(name: string): Observable<PortalAdminModel> {
        return this.get(`User/GetPortalAdmin/${name}`);
    }
}
