import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonImports } from 'src/app/_config/common-modules';
import { AdminUserComponent } from './admin-user.component';

const declarationsAndExports: any[] = [
    AdminUserComponent
];

@NgModule({
    declarations: [...declarationsAndExports
        ],
    exports: [...declarationsAndExports],
    imports: [...CommonImports,  ReactiveFormsModule]
})
export class AdminUserModule { }
