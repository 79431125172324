import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalManager, Spinner } from '@concurrency/angular';
import { Customer } from '../_api/responses/customer.response';
import { DataStore } from '../_portal/data/data.store';

@Component({
    templateUrl: './management.component.html'
})
export class ManagementComponent implements OnInit {
    constructor(
        public router: Router
    ) { }

    public ngOnInit(): void {

    }
}
