import { Injectable } from '@angular/core';
import { ApiClient } from '@concurrency/angular';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { LegacyFamisCompany, LegacyFamisUser } from '../requests/legacy-famis-user.request';
import { Auth0ApiClient } from '../api.client';

@Injectable()
export class LegacyClient extends Auth0ApiClient {
    protected baseUrl = `${environment.urls.subscriptionApi}/api/v1`;

    public upsert(data: LegacyFamisUser): Observable<void> {
        return this.post('_Legacy/FamisUser', data);
    }

    public remove(data: LegacyFamisUser): Observable<void> {
        return this.post('_Legacy/RemoveFamisUser', data);
    }

    public lookup(data: LegacyFamisCompany): Observable<void> {
        return this.post('_Legacy/Customer', data);
    }
}
