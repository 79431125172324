import { ComponentFactoryResolver, Injectable, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { Dictionary } from '@concurrency/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PrimeManager {
    private host?: ViewContainerRef;
    // private _dialogResult = new BehaviorSubject<any | undefined>(undefined);

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver
    ) { }

    public setHost(viewContainerRef: ViewContainerRef): void {
        this.host = viewContainerRef;
    }

    public openDialog(modal: Type<{}>, inputs: Dictionary<any> = {}): void {
        if (this.host == null) {
            throw new Error(`Expected setHost to have been called before attempting to open a modal`);
        }
        this.host.clear();
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(modal);

        const componentRef = this.host.createComponent(componentFactory);
        const inst = componentRef.instance as any;

        // tslint:disable-next-line: forin
        for (const index in inputs) {
            inst[index] = inputs[index];
        }
        // TODO: handle @outputs/event emmiters
    }
    // public close(event: Event, dialogResult: any): void {
    //     if (this.host) {
    //         this.host.clear();
    //         this._dialogResult.next(dialogResult);
    //         //this._dialogResult = dialogResult;
    //     }
    // }
    // public afterClose(): any {
    //     return this._dialogResult;
    // }
}
