import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminErrors } from 'src/app/_api/requests/customer-upsert.request';
import { LicensedUsers } from 'src/app/_api/responses/licensed-users';
import { isDuplicate } from '../custom/custom-validators';
import { CustomerValidationService } from '../customer/customer-validation.service';

@Component({
    selector: 'admin-user',
    templateUrl: './admin-user.component.html',
    styleUrls: ['./admin-user.component.scss']
})
export class AdminUserComponent implements OnInit {

    public adminUserForm!: FormGroup;
    public admins: any[] = [];
    public isEdit = false;
    @Input() public formData!: FormGroup;
    @Input() public isAdd!: boolean;
    @Input() public adminFormValues!: LicensedUsers[];
    @Input() public customErrors!: string[];
    @Input() public customerErrors!: string[];
    @Output() public formChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

    constructor(
        private fb: FormBuilder,
        public customerValidationService: CustomerValidationService) { }

    public ngOnInit(): void {
        this.adminUserForm = this.fb.group({
            AdminUsers: new FormArray([], [Validators.required, isDuplicate('Email')])
        });
        if (this.isAdd) {
            this.addAdmin('', false);
        } else {
            if (this.adminFormValues) {
                this.adminFormValues.forEach((user) => {
                    if (user.Roles && user.Roles.includes('Nav.CustomerAdmin')) {
                        const _admin = {
                            Id: user.UserId,
                            FirstName: user.FirstName,
                            LastName: user.LastName,
                            Phone: '',
                            Email: user.Email,
                            JobTitle: '',
                            Roles: user.Roles,
                            LicenseId: user.Id
                        };
                        if (this.admins.length === 0) {
                            this.addAdmin('', false);
                            this.admins.push(_admin);
                        } else {
                            const checkEmail = this.admins.filter((x) => x.Email === _admin.Email);
                            if (checkEmail.length === 0) {
                                this.addAdmin('', false);
                                this.admins.push(_admin);
                            }
                        }
                    }
                });
                if (this.admins.length > 0) {
                    this.adminUsers.setValue(this.admins);
                }
            }
        }

        this.addGroupToParent();
    }
    get adminUsers(): FormArray { return this.adminUserForm.controls.AdminUsers as FormArray; }

    public addAdmin(e: any, add: boolean): void {
        this.isEdit = add;
        this.adminUsers.push(this.fb.group({
            Id: [''],
            FirstName: ['', [Validators.required, Validators.maxLength(50)]],
            LastName: ['', [Validators.required, Validators.maxLength(50)]],
            Phone: [''],
            Email: ['', [Validators.required]],
            JobTitle: [''],
            Roles: [['Nav.CustomerAdmin'], []],
            LicenseId: ['']
        }));
    }
    public removeAdminUser(index: number): void {
        this.isEdit = false;
        const user = this.adminUsers.at(index).get('Id');
        if (user && user.value) {
            const control = this.adminUsers.at(index).get('Roles');
            if (control) {
                const value = control.value.filter((v: string) => {
                    return (v !== 'Nav.CustomerAdmin' && v !== 'Nav.CustomerUser');
                });
                value.push('Nav.CustomerUser');
                control.patchValue(value);
            }
        } else {
            this.adminUsers.removeAt(index);
        }
    }

    public isAdmin(roles: any): boolean {
        return roles.includes('Nav.CustomerAdmin');
    }

    private addGroupToParent(): void {
        this.formData.addControl('Users', this.adminUsers);
        // this.onFormGroupChange.emit(this.formData);
    }
}
