import { Injectable } from '@angular/core';
import { ApiClient } from '@concurrency/angular';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { Application } from '../responses/application.response';
import { Auth0ApiClient } from '../api.client';

@Injectable()
export class ApplicationClient extends Auth0ApiClient {
    protected baseUrl = `${environment.urls.subscriptionApi}/api/v1`;

    public list(): Observable<Application[]> {
        return this.get(`Application`);
    }
}
