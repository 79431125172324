import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AccessType } from './user/access-type';
import { UserType } from './user/user-type';
import { UserStore } from './user/user.store';

@Injectable()
export class CanAccessGuard implements CanActivate {
    constructor(
        private router: Router,
        private userStore: UserStore
    ) { }

    // TODO: Why does this only work with a promise and not an Observable?
    // see: https://stackoverflow.com/questions/41131476/emptyerror-no-elements-in-sequence
    public canActivate(): Promise<boolean> {
        return new Promise((resolve) => {
            this.userStore.access.onceDefined((access: AccessType) => {
                if (access === 'Authorized') {
                    resolve(true);
                } else if (access === 'Authenticated') {
                    this.router.navigate(['access-denied']);
                    resolve(false);
                } else {
                    this.userStore.lastLoginType.once((lastLoginType?: UserType) => {
                        if (lastLoginType === UserType.Employee) {
                            this.router.navigate(['employee']);
                        } else {
                            this.router.navigate(['customer-login']);
                        }
                        resolve(false);
                    });
                }
            });
        });
    }
}
