import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { SubscriberEntity } from '@concurrency/angular';
import { Util } from '@concurrency/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Dialog } from 'primeng/dialog';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { License } from 'src/app/_api/responses/license.response';
import { Customer } from '../../../_api/responses/customer.response';
import { LicenseUser } from '../../../_api/responses/license-user.response';
import { CustomerService } from '../../user/service/customer.service';

@Component({
    templateUrl: './search-by-customer.component.html',
    styleUrls: ['./search-by-customer.component.scss']
})
export class SearchByCustomerComponent extends SubscriberEntity implements OnInit {
    @HostBinding('class') public classes = 'modal-scroll';
    public moment = moment;
    public Util = Util;
    public display = true;
    public searchTerm = '';
    public searchCustomerFilter = '';
    public disable = true;
    public userList!: LicenseUser[];
    public filteredCustomers!: Customer[];
    public dialogHeader = 'Search by Customer';
    public selectedRow = -1;
    public selected!: Customer;
    public enableRightArrow = true;

    @ViewChild(Dialog) public dialog!: Dialog;
    constructor(
        private customerDetails: CustomerService,
        public activeModal: NgbActiveModal
    ) { super(); }

    public ngOnInit(): void {
        this.setEmptyCustomerList();
        this.filteredCustomers = [];
        this.enableRightArrow = false;
    }

    public sortExpirations(licenses: License[]): string {
        if (licenses != null) {
            licenses.sort((a, b) => {
                return (new Date(b.Expiration).valueOf() - new Date(a.Expiration).valueOf());
            });
            return licenses.map((x: License) => x.Expiration)[0];
        }
        return '';
    }
    public sortLicense(licenses: License[]): any {
        if (licenses != null) {
            licenses.sort((a, b) => {
                return (new Date(b.Expiration).valueOf() - new Date(a.Expiration).valueOf());
            });
            return licenses.map((x: License) => x.Id)[0];
        }
        return '';
    }

    public getSelectedUsers(customer: Customer, index: number): void {
        this.disable = false;
        this.selectedRow = index;
        this.selected = customer;
    }
    public selectedCustomer(event: any): void {
        this.activeModal.close(this.selected);
    }

    public customersPerPage(isLeft: boolean): void {
        const request = this.customerDetails.dataStore.getPageResult(isLeft);
        this.customerDetails.spinner.while(request);

    }

    public searchcustomerfilter(item: any): void {
        const a = this.customerDetails.dataStore.customerList;
        this.searchCustomerFilter = item.value.toLowerCase();
        const request = this.customerDetails.dataStore.searchCustomerbasedonFilter(this.searchCustomerFilter);
        this.customerDetails.spinner.while(request);
       
        this.customerDetails.dataStore.customerList.pipe(takeUntil(this.destroyed)).subscribe((result: Customer[] | undefined) => {
            if (result) {
                this.enableRightArrow = true;
                this.filteredCustomers = result;
                if (this.filteredCustomers.length < 100) {
                    this.enableRightArrow = false;
                }
            }
        });
    }
   
    public setEmptyCustomerList(): void {
        const request = this.customerDetails.dataStore.clearCustomerList();
    }
}
