import { AbstractControl, FormArray, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CustomerEmailModel } from 'src/app/_api/requests/customer-upsert.request';
import { CustomerValidationService } from '../customer/customer-validation.service';
import { CommonDate } from '../feature/date-struct';

export function ValidateEmail(control: AbstractControl): any {
    // tslint:disable-next-line:max-line-length
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regex.test(control.value) && control.value) {
        return { isValidEmail: true };
    }
    return null;
}
export function ValidateName(control: AbstractControl): any {
    const regex = /^[a-zA-Z ]*$/;
    if (!regex.test(control.value) && control.value) {
        return { isValidName: true };
    }
    return null;
}
export function isDuplicate(key: any): any {
    const validator: ValidatorFn | any = (formArray: FormArray) => {
        const totalSelected = formArray.controls
            .map((control) => control.value);
        const names = totalSelected.map((value) => value[key]).filter((x) => x !== '');
        const hasDuplicate = names.some(
            (name, index) => names.indexOf(name, index + 1) !== -1
        );
        return hasDuplicate ? { duplicate: true } : null;
    };
    return validator;
}
export function isValidEmail(email: string): boolean {
    // tslint:disable-next-line:max-line-length
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
}

export function isValidName(name: string): boolean {
    const regex = /^[a-zA-Z0-9 ]*$/;
    return regex.test(name);
}

export function LettersValidator(control: AbstractControl): any {
    const regex = /^([a-zA-Z ]*$)/;
    if (!regex.test(control.value) && control.value) {
        return { isLettersValid: true };
    }
    return null;
}

export function LettersAndNumbersValidator(control: AbstractControl): any {
    const regex = /^([\w ]*$)/;
    if (!regex.test(control.value) && control.value) {
        return { isLettersAndNumbersValid: true };
    }
    return null;
}
export function NumberValidator(control: AbstractControl): any {
    const regex = /^[0-9]*$/;
    if (!regex.test(control.value) && control.value) {
        return { isNumberValid: true };
    }
    return null;
}
export function BusinessPhoneNumberValidator(control: AbstractControl): any {
    const regex = /^[0-9()+-]*$/;
    if (!regex.test(control.value) && control.value) {
        return { isBusinessPhoneNumberValid: true };
    }
    return null;
}
export function dateRangeValidator(form: any): any {

    const startControl = form.get('Start');
    const endControl = form.get('End');
    if (startControl && endControl) {
        const start: any = startControl.value;
        const end: any = endControl.value;
        if (start && end) {
            const isRangeValid = !((CommonDate.fromStruct(end).asDate().setHours(0, 0, 0, 0)
                < CommonDate.fromStruct(start).asDate().setHours(0, 0, 0, 0)));
            return isRangeValid ? null : { dateRange: true };
        }
    }
    return null;
}

export function isCustomerWithOtherPartner(email: string, customerEmailModels: CustomerEmailModel[]): boolean {
    return customerEmailModels.filter((x) => x.Email === email && x.IsCustomerWithOtherPartner === true).length > 0;
}
export function isEmailWithOtherCustomer(email: string, customerEmailModels: CustomerEmailModel[]): boolean {
    return customerEmailModels.filter((x) => x.Email === email && x.IsEmailWithOtherCustomer === true).length > 0;
}
export function isCustomerWithOtherPartnerValidator(customerEmailModels: CustomerEmailModel[]): ValidatorFn | null {
    return (control: AbstractControl): ValidationErrors | null => {
        const customerEmailModel = customerEmailModels.filter((x) => x.Email === control.value)[0];
        return customerEmailModel && customerEmailModel.IsCustomerWithOtherPartner ? { isCustomerWithOtherPartner: true } : null;
    };
}
export function isEmailWithOtherCustomerValidator(customerEmailModels: CustomerEmailModel[]): any {
    return (control: AbstractControl): ValidationErrors | null => {
        const customerEmailModel = customerEmailModels.filter((x) => x.Email === control.value)[0];
        return customerEmailModel && customerEmailModel.IsEmailWithOtherCustomer ? { isEmailWithOtherCustomer: true } : null;
    };
}
