import * as moment from 'moment';

import { Component, OnInit } from '@angular/core';
import { Util } from '@concurrency/core';
import { LicenseUser } from 'src/app/_api/responses/license-user.response';

@Component({
    selector: 'edit-user-modal',
    templateUrl: './edit-user-modal.component.html'
})
export class EditUserModalComponent implements OnInit {
    public licenseUser!: LicenseUser;
    public validLicenseUser?: LicenseUser;
    public moment = moment;

    public ngOnInit(): void {
        this.update();
    }

    public update(): void {
        if (this.licenseUser.FirstName &&
            this.licenseUser.LastName &&
            this.licenseUser.JobTitle &&
            this.licenseUser.Phone &&
            this.licenseUser.IsAdmin != null
        ) {
            this.validLicenseUser = this.licenseUser;
        } else {
            this.validLicenseUser = undefined;
        }
    }

    public initializeForm(licenseUser: LicenseUser): void {
        this.licenseUser = Util.clone(licenseUser);
    }
}
