import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationManager, Spinner } from '@concurrency/angular';
import { Authentication, AuthenticationType } from '@concurrency/authentication';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject } from 'rxjs';
import { UserClient } from 'src/app/_api/clients/user.client';
import { User } from 'src/app/_api/responses/user.response';
import { AccessType } from './access-type';
import { UserType } from './user-type';
import { AuthService } from '@auth0/auth0-angular';
declare let pendo: any;

@Injectable()
export class UserStore {
    private _access = new BehaviorSubject<AccessType | undefined>(undefined);
    private _user = new BehaviorSubject<User | undefined>(undefined);
    private _lastLoginType = new BehaviorSubject<UserType | undefined>(undefined);
    public access = this._access.asObservable();
    public user = this._user.asObservable();
    public lastLoginType = this._lastLoginType.asObservable();

    constructor(
        private logger: NGXLogger,
        private router: Router,
        private spinner: Spinner,
        private authenticationManager: AuthenticationManager,
        private userClient: UserClient,
        private auth: AuthService
    ) { }

    public initializeAuthentication(): void {
        this.authenticationManager.authentication.whileDefined((authentication) => {
            this.findUser();
            if (authentication.previous === AuthenticationType.AAD) {
                this._lastLoginType.next(UserType.Employee);
            } else if (authentication.previous === AuthenticationType.B2C) {
                this._lastLoginType.next(UserType.Commercial);
            }
        });
    }

    public findUser(): void {
        //  this.logger.info(`User Authentication is: ${authentication.current}`); // `

        // if (authentication.current === AuthenticationType.None) {
        //     this._access.next(AccessType.Anonymous);
        // } else {
        const profileRequest = this.userClient.read();
        this.spinner.while(profileRequest);
        profileRequest.onceDefined((profile) => {
            if (profile.Email) {
                pendo.initialize({
                    visitor: {
                        id: profile.Email,
                        roles: profile.Roles
                    }
                });
            }
            this._user.next(profile);
            const isAuthorized = true; // Check if user is an admin
            this._access.next(isAuthorized ? AccessType.Authorized : AccessType.Authenticated);

            if (this.router.url === '/') {
                if (window.location.href.endsWith('dashboard')) {
                    this.router.navigate(['dashboard']);
                } else {
                    this.router.navigate(['']);
                }
            }
        });
        //  }
    }



    public loginEmployee(): void {
        //this.authenticationManager.loginAAD();
        this.auth.loginWithRedirect();
    }

    public signupCustomer(email: string): void {
        // this.authenticationManager.signupB2C({ email });
        this.auth.loginWithRedirect()
    }

    public loginCustomer(): void {
        // this.authenticationManager.loginB2C();
        this.auth.loginWithRedirect()
    }

    public logoutCustomerOrEmployee(): void {
        this.spinner.begin();
        this.auth.logout({
            logoutParams: {
                returnTo: window.location.origin,
            },
        });

        // this.authenticationManager.forget();
        // this.authenticationManager.logoutB2C();
    }
}
