import { Component, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { ModalManager, Spinner, SubscriberEntity } from '@concurrency/angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialog } from 'primeng/confirmdialog';
import { CustomerAdminRequest } from 'src/app/_api/requests/customer-upsert.request';
import { Customer } from 'src/app/_api/responses/customer.response';
import { LicenseUser } from 'src/app/_api/responses/license-user.response';
import { DataStore } from 'src/app/_portal/data/data.store';

@Component({
    selector: 'remove-user-modal',
    templateUrl: './remove-user-modal.component.html'

})
export class RemoveUserModalComponent implements OnInit {
    @Input() public licensedUsers: Customer = {} as Customer;
    @Input() public callbackFunction!: () => void;
    public name = '';
    public isDuplicate = false;
    public hasCancelButton = false;
    public selection: LicenseUser[] | null = null;
    public users: LicenseUser[] = [];
    public deleteUsers: LicenseUser[] = [];
    public allUsers = false;
    public disable = true;
    public dialogHeader = 'Remove Licensed Users';
    @HostBinding('class') public classes = 'modal-scroll';
    @ViewChild(ConfirmDialog) public confirmDialog !: ConfirmDialog;

    constructor(
        public dataStore: DataStore,
        protected spinner: Spinner,
        protected modalManager: ModalManager,
        public activeModal: NgbActiveModal,
        protected confirmationService: ConfirmationService
    ) { }

    public ngOnInit(): void {
        this.users = [];
        this.licensedUsers.Licenses.forEach((x) => {
            x.Users.forEach((y) => {
                if (!(this.users.filter((u) => u.Email === y.Email).length > 0)) {
                    this.users.push({ ...y } as LicenseUser);
                }
            });
        });
    }

    public selectUser(user: LicenseUser, isSelected: boolean): void {
        this.selectedUsers();
    }

    public selectAllUsers(isAllSelected: boolean): void {
        if (isAllSelected) {
            this.users.forEach((x) => {
                (x as any).Selected = true;
            });
        } else {
            this.users.forEach((x) => {
                (x as any).Selected = false;
            });
        }
        this.selectedUsers();
    }
    public selectedUsers(): void {
        this.selection = [];
        this.users.forEach((x) => {
            if ((x as any).Selected && this.selection) {
                this.selection.push(x as LicenseUser);
            }
        });
        if (this.selection && this.selection.length > 0) {
            this.disable = false;
        } else {
            this.disable = true;
        }
        this.deleteUsers = this.selection;
        this.allUsers = this.selection.length === this.users.length ? true : false;
        this.selection = this.selection.length > 0 ? this.selection : null;
    }
    public Save(): void {
        this.confirmationService.confirm({
            message: 'Are you sure you want to delete?',
            header: 'Confirm',
            accept: () => {
                if (this.deleteUsers && this.deleteUsers.length > 0) {
                    this.deleteUsers.forEach((x) => {
                        if (x as any) {
                            delete (x as any).Selected;
                        }
                    });
                    const removeLicenseUsers: LicenseUser[] = [];
                    this.licensedUsers.Licenses.forEach((x) => {
                        x.Users.forEach((y) => {
                            if ((this.deleteUsers.filter((u) => u.Email === y.Email).length > 0)) {
                                removeLicenseUsers.push({ ...y } as LicenseUser);
                            }
                        });
                    });
                    const customerAdmins: CustomerAdminRequest[] = [];
                    removeLicenseUsers.forEach((user: any) => {
                        if (user.UserId) {
                            const customerAdmin: CustomerAdminRequest = {
                                ApplicationShortName: 'Nav',
                                IsAdmin: false,
                                UserId: user.UserId as string
                            };
                            customerAdmins.push(customerAdmin);
                        }
                    });
                    if (customerAdmins.length > 0) {
                        const customerAdminsApi = this.dataStore.UpsertCustomerAdmin(customerAdmins);
                        this.spinner.while(customerAdminsApi);
                    }
                    const request = this.dataStore.removeLicenseUsers(removeLicenseUsers);
                    this.spinner.while(request);
                    request.onceDefined((x) => {
                        this.callbackFunction();
                    },
                        (error) => {
                            this.modalManager.error('Something Went wrong... please re-try or contact Admin');
                            // console.log(error);
                        }, () => {
                            this.spinner.end();
                        });
                }
                this.activeModal.close();
            },
            reject: () => {

            }
        });
    }
}
