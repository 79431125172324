import { NgModule } from '@angular/core';
import { CommonImports } from 'src/app/_config/common-modules';
import { LicenseComponent } from './license.component';

const declarationsAndExports: any[] = [
    LicenseComponent
];

@NgModule({
    declarations: [...declarationsAndExports],
    exports: [...declarationsAndExports],
    imports: [...CommonImports]
})
export class SubscriptionModule { }
