import { Injectable } from '@angular/core';
import { ApiClient } from '@concurrency/angular';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { Order } from '../responses/order.response';
import { Auth0ApiClient } from '../api.client';

@Injectable()
export class OrderClient extends Auth0ApiClient {
    protected baseUrl = `${environment.urls.subscriptionApi}/api`;

    public list(): Observable<Order[]> {
        return of();
        // return this.get(`Orders`);
    }
}
