import { NgModule } from '@angular/core';
import { CommonImports } from '../_config/common-modules';
import { AccessDeniedComponent } from './access-denied.component';
import { CustomerLoginComponent } from './customer-login.component';
import { CustomerModule } from './customer/customer.module';
import { EmployeeLoginComponent } from './employee-login.component';
import { SubscriptionModule } from './license/license.module';
import { ManagementComponent } from './management.component';
import { NoAdminErrorMessageComponent } from './user/no-Admin-error-msg-component';
import { UserModule } from './user/user.module';

@NgModule({
    declarations: [
        ManagementComponent,
        CustomerLoginComponent,
        EmployeeLoginComponent,
        AccessDeniedComponent,
        NoAdminErrorMessageComponent
    ],
    imports: [
        ...CommonImports,
        SubscriptionModule,
        UserModule,
        CustomerModule
    ]
})
export class ManagementModule { }
