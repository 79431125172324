import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationManager, HttpError, ModalManager, NetworkManager, Spinner, SubscriberEntity } from '@concurrency/angular';
import { takeUntil } from 'rxjs/operators';
import { PrimeManager } from './_modal/pmodal.manager';
import { UserStore } from './_portal/user/user.store';
import { AuthService } from '@auth0/auth0-angular';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent extends SubscriberEntity implements OnInit {
    profile: string = "";
    isAuthenticated: boolean = false;
    constructor(
        public spinner: Spinner,
        private authenticationManager: AuthenticationManager,
        private networkManager: NetworkManager,
        private modalManager: ModalManager,
        private primeManager: PrimeManager,
        private router: Router,
        private userStore: UserStore,
        viewContainerRef: ViewContainerRef,
        public auth: AuthService
    ) {
        super();
        this.primeManager.setHost(viewContainerRef);
    }

    public ngOnInit(): void {
        // this.userStore.initializeAuthentication();
        this.auth.isAuthenticated$.subscribe((sucess) => {
            this.isAuthenticated = sucess;
            if (!sucess)
                this.auth.loginWithRedirect({ authorizationParams: { showSignup: 'yes', loginAfterSignUp: 'no' } });
        });

        this.scrollToTopOnRoute();



        this.auth.user$.subscribe(
            (profile) => {
                this.auth.isLoading$.subscribe((isloading) => {
                    if (!isloading && !this.isAuthenticated && !profile) {
                        const url = window.location.href.split('error=unauthorized');
                        if (url?.length > 1) {
                            this.auth.loginWithRedirect({
                                authorizationParams: {
                                    prompt: "login",
                                    error_description: "Please Verify your email address before logging in",
                                }
                            });
                        }
                    }

                    else if (profile?.email_verified === false) {
                        this.auth.loginWithRedirect({
                            authorizationParams: {
                                prompt: "login",
                                error_description: "Please Verify your email address before logging in",
                            }
                        });
                    }
                    else {
                        (this.profile = JSON.stringify(profile, null, 2))
                        if (this.isAuthenticated) {
                            this.userStore.findUser();
                            this.auth.idTokenClaims$.subscribe((claim) => {
                            })
                        }

                    }
                })


                // (this.profile = JSON.stringify(profile, null, 2))

                // if (this.isAuthenticated) {
                //     this.userStore.findUser();
                //     this.auth.idTokenClaims$.subscribe((claim) => {
                //         console.log(claim);
                //     })
                // }

            }

        );

        console.log(this.profile);

        // this.authenticationManager.error
        //     .pipe(takeUntil(this.destroyed))
        //     .whileDefined(() => {
        //         this.spinner.end();
        //         this.modalManager
        //             .error('Your session has expired. You will be taken to the login page.')
        //             .subscribe(() => window.location.reload());
        //     });

        this.networkManager.error
            .pipe(takeUntil(this.destroyed))
            .whileDefined((httpError: HttpError) => {
                const error = (httpError as any).error as { Message: string };
                let message = httpError.status === 400 ? error.Message : 'There was an unexpected error processing your request.';
                if (message === undefined) {
                    message = JSON.stringify(error);
                    const messageend = message.length;
                    message = message.slice(10, messageend - 2);
                }
                this.spinner.end();
                this.modalManager
                    .error(message)
                    .subscribe(() => this.networkManager.acknowledgeErrors());
            });

        // this.authenticationManager.initialize();


    }

    private scrollToTopOnRoute(): void {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }
}
