import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { List, ModalManager, Spinner, SubscriberEntity } from '@concurrency/angular';
import { Util } from '@concurrency/core/dist/src';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { LicenseClient } from 'src/app/_api/clients/license.client';
import { Customer } from 'src/app/_api/responses/customer.response';
import { LicenseUser } from 'src/app/_api/responses/license-user.response';
import { License } from 'src/app/_api/responses/license.response';
import { LicensedUsers } from 'src/app/_api/responses/licensed-users';
import { Subscription } from 'src/app/_api/responses/subscription.response';
import { UserImportService } from 'src/app/_portal/data/account.service';
import { DataStore } from 'src/app/_portal/data/data.store';

@Component({
    selector: 'remove-license-modal',
    templateUrl: './remove-license-modal.component.html',
    styleUrls: ['./remove-license-modal.component.scss']
})
export class RemoveLicenseModalComponent extends SubscriberEntity implements OnInit {
    @ViewChild('userDataFile') public userDataFile?: ElementRef;
    public licenses: License[] = [];
    public tableData: any[] = [];
    public customer?: Customer[];
    public licensenumber: any;
    public disable = true;
    public subscriptionselection: any[] = [];
    public licensedUsers: LicensedUsers[] = [];
    public order = 'desc';
    public columns: { name: string, property: string, get: (x: any) => any }[] = [
        { name: 'Feature', property: 'Feature', get: (x: Subscription) => x.Feature },
        { name: 'Subscription Level', property: 'Level', get: (x: Subscription) => x.Level },
        { name: 'Expiration', property: 'End', get: (x: Subscription) => x.End },
        { name: 'Total Licenses', property: 'UserLimit', get: (x: License) => x.UserLimit },
        { name: 'Unassigned Licenses', property: 'ActiveUsers', get: (x: License) => x.ActiveUsers }
    ];

    public button = true;
    public checked = false;
    public partnerName = '';
    public totalLicenses = 0;
    public selectedCustomer!: Customer;
    public unassignedLicenses = 0;
    public allUsers = false;
    public users: LicenseUser[] = [];

    constructor(
        //  public customer: Customer,
        public activeModal: NgbActiveModal,
        private dataStore: DataStore,
        protected userImportService: UserImportService,
        protected spinner: Spinner,
        protected modalManager: ModalManager
    ) { super(); }

    public ngOnInit(): void {
        this.setCustomer();
        this.users = [];

    }
    public update(event: boolean, event1: string): void {
        this.checked = event;
        const id = event1;
        const y: number = +id;
        const selection = {
            License: '',
            actualsubscription: [''],
            userid: ''
        };
        if (this.checked === true) {
            this.button = false;

            this.licensenumber = this.licensedUsers[y].Id;
            const usermail = this.licensedUsers[y].Email;
            const featurename = this.licensedUsers[y].Feature;
            const level = this.licensedUsers[y].Level;
            selection.actualsubscription = [];
            let i = 0;
            this.selectedCustomer.Subscriptions.forEach((x) => {
                if (x.LicenseId === this.licensenumber && x.Feature === featurename && x.Level === level && x.Feature !== null) {
                    selection.License = this.licensenumber;
                    selection.actualsubscription[i] = x.actualsubscriptionname;
                    i = i + 1;
                }
            });
            this.selectedCustomer.Licenses.forEach((x) => {
                if (x.Id === this.licensenumber) {
                    x.Users.forEach((k) => {
                        if (k.Email === usermail) {
                            if (k.UserId !== undefined) {
                                selection.userid = k.UserId;
                            }
                        }
                    });
                }
            });
            this.subscriptionselection.push(selection);
            this.subscriptionselection = this.subscriptionselection.filter((value, index) =>
                this.subscriptionselection.indexOf(value) === index);
        } else {
            this.button = true;
        }
    }

    public isAdmin(roles: string[]): boolean {
        if (roles && roles.length > 0) {
            return roles.includes('Nav.CustomerAdmin');
        } else {
            return false;
        }
    }

    public setCustomer(): void {
        this.dataStore.customer.pipe(takeUntil(this.destroyed)).whileDefined((result) => {
            this.disable = false;
            this.selectedCustomer = result;
            this.selectedCustomer.Licenses.forEach((x) => {
                x.Users.forEach((y) => { this.users.push({ ...y } as LicenseUser); });
            });
            let activeUsers = 0;
            this.licensedUsers = [];
            if (result.Licenses) {
                result.Licenses.forEach((license) => {
                    this.partnerName = license.Reseller;
                    this.totalLicenses = this.totalLicenses > 0 ? license.UserLimit : this.totalLicenses + license.UserLimit;
                    activeUsers = activeUsers > 0 ? license.ActiveUsers : activeUsers + license.ActiveUsers;
                    license.Users.forEach((user) => {
                        result.Subscriptions.forEach((sub) => {
                            if (license.Id === sub.LicenseId) {
                                const _licenseUser: LicensedUsers = {
                                    Id: user.LicenseId,
                                    Email: user.Email,
                                    FirstName: user.FirstName,
                                    LastName: user.LastName,
                                    Roles: user.Roles,
                                    Feature: sub.Feature,
                                    Level: sub.Level,
                                    Start: sub.Start,
                                    End: sub.End
                                };
                                this.licensedUsers.push(_licenseUser);
                            }
                        });
                        this.licensedUsers.sort((a, b) => (a.Email > b.Email) ? -1 : ((a.Email < b.Email) ? 1 : 0));
                    });
                });
                this.unassignedLicenses = this.totalLicenses - activeUsers;
            }
        });
        this.dataStore.customerList.pipe(takeUntil(this.destroyed)).whileDefined((customer) => {
            this.customer = customer;
        });
    }
}
