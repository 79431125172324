import { Injectable } from '@angular/core';
import { ApiClient } from '@concurrency/angular';
import { Observable } from 'rxjs/internal/Observable';
import { FeatureRequest, LicenseDelete } from 'src/app/management/feature/feature-request.model';
import { environment } from 'src/environments/environment';
import { LicensedUserUpsertRequest } from '../requests/licensed-user-upsert';
import { LicenseUser, LicenseUserRequest } from '../responses/license-user.response';
import { License } from '../responses/license.response';
import { Auth0ApiClient } from '../api.client';

@Injectable()
export class LicenseClient extends Auth0ApiClient {
    [x: string]: any;
    protected baseUrl = `${environment.urls.subscriptionApi}/api/v1`;

    public upsert(data: LicenseUser[]): Observable<void> {
        return this.post('LicenseUser/AuthLicenseUser', data);
    }

    public remove(data: LicenseUser[]): Observable<void> {
        return this.delete('LicenseUser', data);
    }

    public removeLicense(data: LicenseUser[]): Observable<void> {
        return this.delete('License', data);
    }
    public reinvite(data: LicenseUser[]): Observable<void> {
        return this.post('LicenseUser/Reinvite', data);
        //  data.map((x) => ({ userId: x.UserId, licenseId: x.LicenseId }))
    }

    public upsertLicense(data: FeatureRequest): Observable<string> {
        return this.post('License', data);
    }
    public upsertUsers(data: LicensedUserUpsertRequest[]): Observable<any> {
        return this.post('LicenseUser/AuthLicenseUser', data);
    }
}
