import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { from } from 'rxjs';
import { CommonImports } from 'src/app/_config/common-modules';
import { CustomerModule } from '../customer/customer.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EditUserModalComponent } from './edit-user-modal.component';
import { SelectSubModalComponent } from './license-select.modal.component';
import { NewUserModalComponent } from './new-user-modal.component';
import { RemoveLicenseModalComponent } from './remove-license-modal/remove-license-modal.component';
import { RemoveUserModalComponent } from './remove-user-modal.component';
import { CustomerService } from './service/customer.service';
import { UserListComponent } from './user-list.component';

const declarationsAndExports: any[] = [
    UserListComponent,
    NewUserModalComponent,
    EditUserModalComponent,
    SelectSubModalComponent,
    RemoveUserModalComponent,
    RemoveLicenseModalComponent
];

@NgModule({
    declarations: [...declarationsAndExports, DashboardComponent, RemoveLicenseModalComponent],
    exports: [...declarationsAndExports],
    providers: [CustomerService, ConfirmationService],
    imports: [...CommonImports, CustomerModule, ReactiveFormsModule, ConfirmDialogModule]
})
export class UserModule { }
