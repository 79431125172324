
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AADSettings, B2CSettings } from '@concurrency/authentication';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { ApiClients } from './_config/api-clients';
import { aadSettings, b2cSettings } from './_config/authentication.configuration';
import { routeConfig } from './_config/route.configuration';
import { AppComponent } from './app.component';
import { HomeComponent } from './home.component';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

// Modules
import { AuthenticationManager, ConcurrencyModule, NgbDateParserFactory } from '@concurrency/angular';
import { LoggerModule } from 'ngx-logger';
import { CommonImports } from './_config/common-modules';
import { ManagementModule } from './management/management.module';
import { SearchModule } from './management/search/search.module';

export const AppDefinition: NgModule = {
    declarations: [
        AppComponent,
        HomeComponent
    ],
    imports: [
        RouterModule.forRoot(routeConfig),
        LoggerModule.forRoot({ level: environment.loggerLevel }),
        ConcurrencyModule.forRoot(environment),
        AuthModule.forRoot(environment.auth0),
        ManagementModule,
        SearchModule,
        ...CommonImports
    ],
    providers: [
        ...ApiClients,
        // TODO: Properly define this somewhere else
        {
            provide: NgbDateParserFormatter,
            useFactory: NgbDateParserFactory
        },
        {
            provide: AADSettings,
            useValue: aadSettings
        }, {
            provide: B2CSettings,
            useValue: b2cSettings
        },
        {
            provide: AuthenticationManager,
            deps: [AADSettings, B2CSettings]
        },
        { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true }
    ],
    bootstrap: [AppComponent]
};

@NgModule(AppDefinition)
export class AppModule { }
