import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonImports } from 'src/app/_config/common-modules';
import { AdminUserModule } from '../admin-user/admin-user.module';
import { FeatureModule } from '../feature/feature.module';
import { AddCustomerComponent } from './add-customer/add-customer.component';
import { CustomerUpsertComponent } from './customer-upsert/customer-upsert.component';
import { CustomerValidationService } from './customer-validation.service';
import { CustomerComponent } from './customer.component';

const declarationsAndExports: any[] = [
    CustomerComponent,
    CustomerUpsertComponent,
    AddCustomerComponent
];

@NgModule({
    declarations: [...declarationsAndExports],
    exports: [...declarationsAndExports],
    imports: [...CommonImports, FeatureModule, ReactiveFormsModule, AdminUserModule],
    providers: [CustomerValidationService]
})
export class CustomerModule { }
