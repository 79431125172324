import * as moment from 'moment';

import { Util } from '@concurrency/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Moment } from 'moment';

// tslint:disable-next-line: no-empty-interface
export interface DateStruct extends NgbDateStruct {
    // NOTE: This exists to avoid taking a direct dependency on NgbDateStruct, which may someday be removed
}

export class CommonDate implements DateStruct {

    public static fromStruct(date: NgbDateStruct): CommonDate {
        return new CommonDate(date.year, date.month, date.day);
    }

    public static fromString(date: string): CommonDate {
        return this.fromDate(new Date(date));
    }

    public static fromDate(date: Date): CommonDate {
        return new CommonDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
    }
    constructor(public year: number, public month: number, public day: number) { }

    public asDate(): Date {
        return new Date(this.year, this.month - 1, this.day);
    }

    public asString(): string {
        const day = ('0' + this.day.toString()).slice(-2);
        const month = ('0' + this.month.toString()).slice(-2);

        return `${this.year}-${month}-${day}T12:00:00`;
    }

    public asMoment(): Moment {
        return moment(this.asString());
    }

    public asStruct(): DateStruct {
        return { year: this.year, month: this.month, day: this.day };
    }

    public equals(date: string): boolean {
        return Util.isISODateEqual(this.asString(), date);
    }
}
