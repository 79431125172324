import { Injectable } from '@angular/core';
import { ApiClient } from '@concurrency/angular';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { CustomerAdminRequest, CustomerEmailModel, CustomerUpsertRequest, UserCustomerLogModel } from '../requests/customer-upsert.request';
import { Customer } from '../responses/customer.response';
import { Auth0ApiClient } from '../api.client';

@Injectable()
export class CustomerClient extends Auth0ApiClient {
    protected baseUrl = `${environment.urls.subscriptionApi}/api/v1`;

    public list(name: string, page: number, searchTerm?: string): Observable<Customer[]> {
        return this.get(`Customer/application/${name}/${page}/${searchTerm}`);
    }

    public emailList(name: string, page: number, searchTerm?: string): Observable<Customer[]> {
        return this.get(`Customer/application/email/${name}/${page}/${searchTerm}`);
    }

    public find(id: string): Observable<Customer> {
        return this.get(`Customer/${id}`);
    }
    public CustomerUpsert(CustomerRequestModel: any): Observable<any> {
        return this.post(`Customer/CustomerUpsert`, CustomerRequestModel);
    }
    public UpsertAsync(customerUpsertRequest: CustomerUpsertRequest): Observable<any> {
        return this.post(`Customer`, customerUpsertRequest);
    }
    public UpsertUserCustomerAsync(userCustomerLogModel: UserCustomerLogModel): Observable<any> {
        return this.post(`Customer/UpsertUserCustomerLog`, userCustomerLogModel);
    }
    public GetUserCustomerLog(id: string): Observable<any> {
        return this.get(`Customer/UserCustomerLog/${id}`);
    }
    public UpsertCustomerAdmin(customerAdminRequest: CustomerAdminRequest[]): Observable<any> {
        return this.post(`Customer/UpsertCustomerAdmin`, customerAdminRequest);
    }
    public IsEmailsWithCustomer(customerEmailModels: CustomerEmailModel[]): Observable<any> {
        return this.post(`Customer/IsEmailsWithCustomer`, customerEmailModels);
    }
    public DeleteCustomerLicenseUsers(emails: string[]): Observable<any> {
        return this.post(`Customer/DeleteCustomerLicenseUsers`, emails);
    }
    public GetCustomersByUserId(userId: string): Observable<string[]> {
        return this.get(`Customer/User/${userId}`, userId);
    }
}
