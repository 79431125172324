// Angular Modules
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ConcurrencyModule } from '@concurrency/angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PortalModule } from '../_portal/portal.module';

export const CommonImports = [
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    RouterModule,
    HttpClientModule,
    NgbModule,
    ConcurrencyModule,
    PortalModule
];
