import { Component } from '@angular/core';
import { SubscriberEntity } from '@concurrency/angular';
import { UserStore } from '../_portal/user/user.store';

@Component({
    templateUrl: 'access-denied.component.html'
})
export class AccessDeniedComponent extends SubscriberEntity {
    constructor(
        private userStore: UserStore
    ) { super(); }

    public logout(): void {
        this.userStore.logoutCustomerOrEmployee();
    }
}
