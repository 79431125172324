import { Injectable } from '@angular/core';
import { Spinner } from '@concurrency/angular';
import { BehaviorSubject } from 'rxjs';
import { CustomerClient } from 'src/app/_api/clients/customer.client';
import { Customer } from 'src/app/_api/responses/customer.response';
import { DataStore } from 'src/app/_portal/data/data.store';

@Injectable({
    providedIn: 'root'
})
export class CustomerService {
    constructor(
        public dataStore: DataStore,
        public spinner: Spinner
    ) { }

    public getSelectedCustomerDetails(customer: Customer): void {
        const request = this.dataStore.setCustomer(customer.Id);
        this.spinner.while(request);
        request.once();
    }

}
