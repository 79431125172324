import { NgModule } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { CommonImports } from '../../_config/common-modules';
import { SearchByCustomerComponent } from './search-by-customer/search-by-customer.component';
import { SearchByEmailComponent } from './search-by-email/search-by-email.component';

@NgModule({
    declarations: [
        SearchByCustomerComponent,
        SearchByEmailComponent
    ],
    imports: [
        ...CommonImports,
        DialogModule
    ]
})
export class SearchModule { }
