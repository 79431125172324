import { EnvironmentConfiguration } from '@concurrency/angular';
import { NgxLoggerLevel } from 'ngx-logger';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: EnvironmentConfiguration = {
    production: false,
    name: 'dev',
    urls: {
        subscriptionApi: 'https://dev-ussc-ifs-shared-as-auth0-api.azurewebsites.net',
        famisRuntimeApi: 'https://famis-dev-runtime-api.duffandphelps.com',
        famisConfigApi: 'https://famis-dev-config-api.duffandphelps.com',
        famis: 'https://famis-dev.duffandphelps.com',
        navigator: 'https://coc-dev.duffandphelps.com',
        fastspring: 'https://concurrency.test.onfastspricom'
    },
    authentication: {
        aad: {
            client: '9662e3c0-3816-43da-a963-fefd4223158c',
            tenant: '781802be-916f-42df-a204-78a2b3144934',
            endpoints: [
                'https://duffandphelps.onmicrosoft.com/db6aeb94-c157-4c59-8eca-16d1b05ada37',
                'bb2ca938-052f-4556-9b3c-1afbeaf50fbe'
            ],
            durationMinutes: 30
        },
        b2c: {
            provider: 'b2clogin.com',
            workflow: 'Redirect',
            client: '1da025a8-081e-4108-9363-c7506811f68f',
            tenant: 'devusdpb2c',
            scopes: ['https://devusdpb2c.onmicrosoft.com/authorization/full'],
            policies: {
                signup: 'B2C_1_signup',
                signin: 'B2C_1_signup_signin',
                signinSignup: 'B2C_1_signup_signin',
                forgotPassword: 'B2C_1_reset_password'
            }
        }
    },
    applicationInsights: {
        name: 'dev-us-coc-wa-01',
        key: 'd90417b2-d70f-49ef-8d6c-e6ca08212943'
    },
    loggerLevel: NgxLoggerLevel.TRACE,
    auth0: {
        domain: 'pearl-dev.eu.auth0.com',
        clientId: '6pcvxc3OfzxduyZkq20AT3ReA6vjDw8U',

        authorizationParams: {
            redirect_uri: window.location.origin,
            scope: 'openid profile email',
            audience: '50fb36a6-343e-4528-8352-9240e9817767'
        },
        httpInterceptor: {
            allowedList: [
                'https://dev-ussc-ifs-shared-as-auth0-api.azurewebsites.net/*',
                'https://cdn.pendo.io/agent/static/'
            ],
        }
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
