import { Injectable } from '@angular/core';
import { ApiClient } from '@concurrency/angular';
import { forkJoin } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FamisCountry, FamisCountryLanguage } from '../responses/legacy-famis-country.response';
import { Auth0ApiClient } from '../api.client';

@Injectable()
export class FamisCountryClient extends Auth0ApiClient {
    protected baseUrl = `${environment.urls.famisConfigApi}/api`;

    public read(): Observable<FamisCountry[]> {
        const getCountryLanguages = this.get('Countries/CountryLanguage');
        const getCountries = this.get('Countries');

        return forkJoin([getCountries, getCountryLanguages]).pipe(
            map(([getCountriesResult, getCountryLanguagesResult]) => {
                const countries = getCountriesResult as FamisCountry[];
                const countryLanguages = getCountryLanguagesResult as FamisCountryLanguage[];

                return countries.filter((country) => {
                    return countryLanguages.findIndex((c) => c.country.toUpperCase() === country.alpha2.toUpperCase()) !== -1;
                });
            })
        );
    }
}
