import { Injectable } from '@angular/core';
import { ApiClient } from '@concurrency/angular';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { ContractSearchResult, FamisGroup, FamisMember } from '../responses/legacy-famis-contract.response';
import { Auth0ApiClient } from '../api.client';

@Injectable()
export class FamisClient extends Auth0ApiClient {
    protected baseUrl = `${environment.urls.famisRuntimeApi}/api`;

    public searchContracts(query: string): Observable<ContractSearchResult[]> {
        return this.get(`contract/search?query=${query}`);
    }

    public searchPortalGroup(query: string): Observable<FamisGroup[]> {
        return this.get(`group/search/portalgroup?query=${query}`);
    }

    public getGroupMembers(groupId: string): Observable<FamisMember[]> {
        return this.get(`insurance/group/${groupId}/members`);
    }
}
