import * as moment from 'moment';

import { Component, OnInit } from '@angular/core';
import { Customer } from 'src/app/_api/responses/customer.response';
import { DataStore } from 'src/app/_portal/data/data.store';

@Component({
    templateUrl: 'customer.component.html'
})
export class CustomerComponent implements OnInit {
    public customer?: Customer;
    public moment = moment;

    constructor(
        private dataStore: DataStore
    ) { }

    public ngOnInit(): void {
        this.dataStore.customer.onceDefined((customer) => this.customer = customer);
    }
}
