import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ConcurrencyAuthenticator } from '@concurrency/authentication';
import { aadSettings, b2cSettings } from './app/_config/authentication.configuration';
import { setupFortAwesome } from './app/_config/fontawesome.configuration';
import { AppModule } from './app/app.module';

setupFortAwesome();

// const windowIdentity = ConcurrencyAuthenticator.initializeWindow(b2cSettings, aadSettings);
// if (windowIdentity === 'MainWindow') {
//     bootstrapApp();
// } else {
//     // TODO: Display progress indicator and/or Timeout after some duration
// }
bootstrapApp();
function bootstrapApp(): void {
    enableProdMode();

    platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .catch((err) => {
            console.error(err);
        });
}
