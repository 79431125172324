import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { List, ModalManager, Spinner, SubscriberEntity } from '@concurrency/angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { CustomerEmailModel } from 'src/app/_api/requests/customer-upsert.request';
import { Customer } from 'src/app/_api/responses/customer.response';
import { LicenseUser } from 'src/app/_api/responses/license-user.response';
import { License } from 'src/app/_api/responses/license.response';
import { Subscription } from 'src/app/_api/responses/subscription.response';
import { UserImportService } from 'src/app/_portal/data/account.service';
import { DataStore } from 'src/app/_portal/data/data.store';
import {
    isDuplicate
} from '../custom/custom-validators';
import { CustomerValidationService } from '../customer/customer-validation.service';
@Component({
    selector: 'new-user-modal',
    templateUrl: './new-user-modal.component.html'
})
export class NewUserModalComponent extends SubscriberEntity implements OnInit, OnDestroy {
    @ViewChild('userDataFile') public userDataFile?: ElementRef;
    public licenses: License[] = [];
    public tableData: any[] = [];
    public customer?: Customer;
    public licensenumber: any;
    public subscriptionselection: any[] = [];
    public columns: { name: string, property: string, get: (x: any) => any }[] = [
        { name: 'LicenseId', property: 'LicenseId', get: (x: Subscription) => x.LicenseId },
        { name: 'Feature', property: 'Feature', get: (x: Subscription) => x.Feature },
        { name: 'Subscription Level', property: 'Level', get: (x: Subscription) => x.Level },
        { name: 'Expiration', property: 'End', get: (x: Subscription) => x.End },
        { name: 'Total Licenses', property: 'UserLimit', get: (x: License) => x.UserLimit },
        { name: 'Unassigned Licenses', property: 'ActiveUsers', get: (x: License) => x.ActiveUsers }
    ];
    public userList: List<any> = new List<LicenseUser>([]);
    public licenseInvites: LicenseUser[] = [];
    public button = true;
    public file: any;
    public checked = false;
    public partnerName = '';
    public totalLicenses = 0;
    public activeusers = 0;
    public isvalidfile = false;
    public fileDataList: LicenseUser[] = [];
    public selectedCustomer!: Customer;
    public unassignedLicenses = 0;
    public userForm!: FormGroup;
    public errors: string[] = [];
    public activeUsers = 0;
    public selectedLicensId: string[] | undefined;
    public selectedSubscriptions: Subscription[] = [];
    constructor(
        //  public customer: Customer,
        public activeModal: NgbActiveModal,
        private dataStore: DataStore,
        protected userImportService: UserImportService,
        protected spinner: Spinner,
        protected modalManager: ModalManager,
        private fb: FormBuilder,
        public customerValidationService: CustomerValidationService
    ) { super(); }
    public ngOnInit(): void {
        this.customerValidationService.resetAll();
        this.userForm = this.fb.group({
            users: new FormArray([], [Validators.required, isDuplicate('Email')])
        });
        this.addUsers();
        this.userForm.valueChanges.subscribe((x) => {
            this.isValidForm();
        });
        this.dataStore.customer.pipe(takeUntil(this.destroyed)).whileDefined((customer) => {
            this.customer = customer;
            this.selectedCustomer = customer;
            const subscriptionIds = this.selectedCustomer.Subscriptions;
            this.selectedCustomer.Subscriptions = subscriptionIds.sort(
                (a, b) => (a.LicenseId > b.LicenseId) ? 1 : ((b.LicenseId > a.LicenseId) ? -1 : 0));
            if (customer.Licenses) {
                customer.Licenses.forEach((license) => {
                    this.partnerName = license.CustomerName;
                    this.totalLicenses = this.totalLicenses > 0 ? license.UserLimit : this.totalLicenses + license.UserLimit;
                    this.activeusers = this.activeusers + parseInt(license.ActiveUsers.toString(), 10);
                    this.licensenumber = license.Id;
                });
            }
            this.unassignedLicenses = this.totalLicenses - this.activeusers;
        });
    }

    get users(): FormArray { return this.userForm.controls.users as FormArray; }
    public addUsers(): void {
        for (let index = 0; index < 5; index++) {
            this.users.push(this.fb.group({
                FirstName: ['', [Validators.maxLength(50)]],
                LastName: ['', [Validators.maxLength(50)]],
                Email: ['', []],
                IsAdmin: ['false', []],
                IsActive: ['true']
            }));
        }
    }
    public update(event: boolean, event1: string): void {
        this.checked = event;
        const id = event1;
        const y: number = +id;
        if (this.checked === true) {
            this.licensenumber = this.selectedCustomer.Subscriptions[y].LicenseId;
            this.subscriptionselection.push(this.selectedCustomer.Subscriptions[y].actualsubscriptionname);
            this.subscriptionselection = this.subscriptionselection.filter((value, index) =>
                this.subscriptionselection.indexOf(value) === index);
            this.selectedSubscriptions.push(this.selectedCustomer.Subscriptions[y]);
        } else {
            const id1 = event1;
            const y1: number = +id1;
            this.subscriptionselection.splice(y1, 1);
            this.selectedSubscriptions = this.selectedSubscriptions
                .filter((ss) => ss.LicenseId !== this.selectedCustomer.Subscriptions[y1].LicenseId);
        }
        this.isValidForm();
        if (this.isvalidfile === true) {
            this.isValidBulkFile(true);
        }
    }

    public isValidFormRow(rowValue: any): boolean {
        let hasAllFields: boolean;
        if (rowValue.Email === '' && rowValue.FirstName === '' && rowValue.LastName === '') {
            hasAllFields = true;
        } else if (rowValue.Email !== '' && rowValue.FirstName !== '' && rowValue.LastName !== '') {
            hasAllFields = true;
        } else {
            hasAllFields = false;
        }
        return hasAllFields;
    }
    public isValidBulkFile(isBulkFile: boolean): void {
        if (isBulkFile) {
            const isValid = true;
            this.button = !(isValid && this.subscriptionselection.length > 0);
        }
    }
    public isValidForm(isFromForm: boolean = true): void {
        if (isFromForm) {
            let isValid = false;
            let users: LicenseUser[] = [];
            if (this.userForm.valid) {
                users = (this.users.value) as LicenseUser[];
                const valids: boolean[] = [];
                if (users) {
                    for (const user of users) {
                        valids.push(this.isValidFormRow(user));
                    }
                    if (valids.filter((x) => x === false).length > 0) {
                        isValid = false;
                    } else {
                        const allEmprty: boolean[] = [];
                        for (const user of users) {
                            if (user.Email === '' && user.FirstName === '' && user.LastName === '') {
                                allEmprty.push(true);
                            } else {
                                allEmprty.push(false);
                            }
                        }
                        if (allEmprty.filter((x) => x === true).length === users.length) {
                            isValid = false;
                        } else {
                            isValid = true;
                        }
                    }
                }
            } else {
                isValid = false;
            }
            this.button = !(isValid && this.subscriptionselection.length > 0);
        }
    }
    public addfile(event: any): void {
        if (event !== null && event.target && event.target.files) {
            // this.button = false;
            this.file = event.target.files[0];
            // let fileReader = new FileReader();
            const dataTransfer: DataTransfer = event.target as DataTransfer;
            const file = dataTransfer.files[0];
            const subscriptionId = this.licensenumber; // customer.Licenses[0].Id;
            const request = this.userImportService.createLicenseUsers(file, subscriptionId);
            this.spinner.while(request);
            this.fileDataList = [];
            request.subscribe((result) => {
                this.fileDataList = result.licenseUsers;
                // this.fileDataList
                // console.log(this.fileDataList);
                if (result.licenseUsers.length > 0) {
                    this.isValidBulkFile(true);
                    this.isvalidfile = true;
                    this.fileDataList.forEach((x) => {
                        this.licenseInvites.push(x);
                    });
                } else {
                    this.isValidBulkFile(false);
                }
            }, (error) => {
                this.modalManager.error(error.message);
                this.spinner.end();
            });
        }
    }
    public clearFileData(): void {
        if (this.userDataFile) {
            this.isvalidfile = false;
            // console.log(this.userDataFile.nativeElement.files);
            this.userDataFile.nativeElement.value = '';
            this.file = null;
            this.fileDataList.forEach((x) => {
                this.licenseInvites.splice(this.licenseInvites.indexOf(x), 1);
            });
            this.fileDataList = [];
            // console.log(this.userDataFile.nativeElement.files);
        }
    }
    public save(): void {
        this.customerValidationService.clearErrors();
        this.setValidationMessages();
        if (this.customerValidationService.customErrors.length > 0) {
            this.customerValidationService.allowSubmit = false;
            return;
        }
        if (this.userForm.valid) {
            this.setlicenseInvites();
        } else {
            return;
        }
        this.verifyBusinessValidations(this.licenseInvites);
    }

    public setValidationMessages(customerEmailModels?: CustomerEmailModel[]): void {
        for (let index = 0; index < this.users.value.length; index++) {
            const user = this.users.value[index];
            const control = this.users.controls[index] as FormGroup;
            this.customerValidationService.setCustomValidationsForUser(control, user, customerEmailModels);
        }
        if (customerEmailModels && customerEmailModels.length) {
            this.customerValidationService.addCutomerErrors(customerEmailModels);
        }
        this.setValidationForNoLicenseLimit();
    }
    public setValidationForNoLicenseLimit(): void {
        if (this.selectedSubscriptions.length > 0) {
            let isLicenceLimitNotAvailable = false;
            this.selectedSubscriptions.forEach((ss) => {
                if (ss.UserLimit - ss.ActiveUsers <= 0) {
                    isLicenceLimitNotAvailable = true;
                }
            });
            if (isLicenceLimitNotAvailable) {
                this.customerValidationService.addNoLicenceAvailableError();
            }
        }
    }
    public setlicenseInvites(): void {
        this.licenseInvites = [];
        for (const user of this.users.value) {
            let roles: string[] = [];
            const isUserhaveAdminRole = (this.selectedCustomer.Licenses.filter((license: License) => {
                return (license.Users.filter((licenseUser: LicenseUser) => {
                    return (licenseUser.Email === user.Email
                        && (licenseUser.Roles.filter((role: string) => {
                            if (role.includes('Admin')) {
                                roles = licenseUser.Roles;
                            }
                            return role.includes('Admin');
                        }).length > 0));
                }).length > 0);
            }).length > 0) ? true : false;
            if (isUserhaveAdminRole && roles.length > 0) {
                user.IsAdmin = true;
                user.Roles = roles;
            } else {
                user.Roles = ['Nav.CustomerUser'];
            }
            if (user.Email !== '' && user.FirstName !== '' && user.LastName !== '') {
                this.licenseInvites.push(user);
            }
        }
        this.fileDataList.forEach((x) => {
            this.licenseInvites.push(x);
        });
        this.licenseInvites.forEach((x) => {
            x.LicenseId = this.licensenumber;
            x.features = this.subscriptionselection;
        });
    }
    public verifyBusinessValidations(licenseInvites: LicenseUser[]): void {
        let customerEmailModels: CustomerEmailModel[] = [];
        if (CustomerValidationService.customerEmailModels.length > 0) {
            customerEmailModels = CustomerValidationService.customerEmailModels;
        }
        CustomerValidationService.customerEmailModels = [];
        this.licenseInvites.forEach((lu) => {
            let reseller = '';
            this.selectedCustomer.Licenses.forEach((customer) => {
                if (customer.Reseller) {
                    reseller = customer.Reseller;
                }
            });
            if (reseller === '') {
                reseller = this.dataStore.isNavigatorInternalAdmin ? 'Internal' : '';
                reseller = this.dataStore.isNavigatorValuSourceAdmin ? 'ValuSource' : '';
            }
            const customerEmailModel: CustomerEmailModel = {
                CustomerId: this.selectedCustomer.Id,
                Email: lu.Email,
                IsEmailWithOtherCustomer: true,
                ResellerName: reseller,
                IsCustomerWithOtherPartner: true
            } as CustomerEmailModel;
            CustomerValidationService.customerEmailModels.push(customerEmailModel);
        });
        if (customerEmailModels.length > 0 && CustomerValidationService.customerEmailModels.length > 0) {
            let differenceCount = 0;
            CustomerValidationService.customerEmailModels.forEach((x) => {
                if (customerEmailModels.filter((m) => m.Email === x.Email).length === 0) {
                    differenceCount += 1;
                }
            });
            if (differenceCount > 0) {
                this.customerValidationService.allowSubmit = false;
            }
        }
        this.saveAfterBusinessValidations(CustomerValidationService.customerEmailModels);
    }

    public saveAfterBusinessValidations(customerEmailModels: CustomerEmailModel[]): void {
        this.customerValidationService.verifyPartners(customerEmailModels).once((m: CustomerEmailModel[]) => {
            this.setValidationMessages(m);
            if (this.customerValidationService.customErrors.length > 0) {
                return;
            }
            if (!(this.customerValidationService.customerAdminErrors.length)) {
                this.customerValidationService.allowSubmit = true;
            }
            if (!(this.customerValidationService.customErrors.length > 0) &&
                this.customerValidationService.allowSubmit && this.licenseInvites !== null) {
                if (this.customerValidationService.customerAdminErrors.length) {
                    const deleteUsers = this.customerValidationService.deleteLicenseUsers();
                    this.spinner.while(deleteUsers);
                    deleteUsers.once(() => {
                        this.activeModal.close(this.licenseInvites);
                    });
                } else {
                    this.activeModal.close(this.licenseInvites);
                }
            }
            this.customerValidationService.allowSubmit = true;
            this.button = this.userForm.valid ? false : true;
        });
    }
    public ngOnDestroy(): void {
        this.customerValidationService.resetAll();
    }
}
