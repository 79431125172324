import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonImports } from 'src/app/_config/common-modules';
import { FeatureComponent } from './feature.component';

const declarationsAndExports: any[] = [
    FeatureComponent
];

@NgModule({
    declarations: [...declarationsAndExports],
    exports: [...declarationsAndExports],
    imports: [...CommonImports, ReactiveFormsModule]
})

export class FeatureModule { }
