import * as moment from 'moment';

import { Component, OnInit } from '@angular/core';
import { License } from 'src/app/_api/responses/license.response';
import { DataStore } from 'src/app/_portal/data/data.store';
import { environment } from '../../../environments/environment';

@Component({
    templateUrl: 'license.component.html'
})
export class LicenseComponent implements OnInit {
    public licenses!: License[];
    public moment = moment;

    constructor(
        private dataStore: DataStore
    ) { }

    public ngOnInit(): void {
        this.dataStore.customer.onceDefined((customer) => this.licenses = customer.Licenses);
    }

    public openFastSpring(): void {
        window.open(environment.urls.fastSpring);
    }
}
