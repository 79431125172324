import { Component, OnInit } from '@angular/core';
import { Spinner } from '@concurrency/angular';
import { UserCustomerLogModel } from 'src/app/_api/requests/customer-upsert.request';
import { Customer } from 'src/app/_api/responses/customer.response';
import { DataStore } from 'src/app/_portal/data/data.store';
import { UserStore } from 'src/app/_portal/user/user.store';

@Component({
    selector: 'dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    constructor(
        protected dataStore: DataStore,
        protected spinner: Spinner,
        protected userStore: UserStore
    ) { }

    public ngOnInit(): void {
        // const request = this.dataStore.setCustomerList();
        // this.spinner.while(request);
        // // request.once();
        // request.once(() => {
        //     this.userStore.user.onceDefined((user) => {
        //         if (user && user.Id) {
        //             const userCustomerLog = this.dataStore.UserCustomerLog(user.Id);
        //             this.spinner.while(userCustomerLog);
        //             userCustomerLog.once((data: UserCustomerLogModel) => {
        //                 this.dataStore.customerList.whileDefined((customers: Customer[] | undefined) => {
        //                     if (customers && customers.filter((customer) => customer.Id === data.CustomerId)[0]) {
        //                         const setLoggedCustomer = this.dataStore.setCustomer(data.CustomerId);
        //                         this.spinner.while(setLoggedCustomer);
        //                     }
        //                 });
        //             });
        //         }
        //     });
        // });
    }

}
