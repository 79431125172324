import { Component, OnInit } from '@angular/core';
import { ModalManager } from '@concurrency/angular';
import { License } from 'src/app/_api/responses/license.response';
import { DataStore } from 'src/app/_portal/data/data.store';

@Component({
    templateUrl: './license-select.modal.component.html'
})
export class SelectSubModalComponent implements OnInit {
    public selected?: string;
    public licenses?: License[];
    public userCount?: number;
    public cancellation?: {};

    constructor(
        private dataStore: DataStore,
        private modalManager: ModalManager
    ) { }

    public ngOnInit(): void {
        this.dataStore.customer.onceDefined((customer) => {
            if (customer) {
                this.licenses = customer.Licenses.filter((license: License) => {
                    if (this.userCount === undefined) {
                        throw new Error('Expected "userCount" to be defined.');
                    }
                    const seatsRemaining = license.UserLimit - license.Users.length;
                    return this.userCount <= seatsRemaining;
                });

                if (this.licenses.length === 0) {
                    this.cancellation = {};
                    this.modalManager.error(`You do not have any subscriptions which can support assigning ${this.userCount} users.`);
                }
            }
        });
    }
}
