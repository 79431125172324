import { ApplicationClient } from '../_api/clients/application.client';
import { CustomerClient } from '../_api/clients/customer.client';
import { FamisCountryClient } from '../_api/clients/famis-country.client';
import { FamisClient } from '../_api/clients/famis.client';
import { LegacyClient } from '../_api/clients/legacy.client';
import { LicenseClient } from '../_api/clients/license.client';
import { OrderClient } from '../_api/clients/order.client';
import { UserClient } from '../_api/clients/user.client';

export const ApiClients = [
    ApplicationClient,
    UserClient,
    LicenseClient,
    CustomerClient,
    FamisClient,
    FamisCountryClient,
    OrderClient,
    LegacyClient
];
