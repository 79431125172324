import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { List, ModalManager, Spinner, SubscriberEntity } from '@concurrency/angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserClient } from 'src/app/_api/clients/user.client';
import { CustomerAdminRequest, UserCustomerLogModel } from 'src/app/_api/requests/customer-upsert.request';
import { LicensedUserUpsertRequest } from 'src/app/_api/requests/licensed-user-upsert';
import { Customer } from 'src/app/_api/responses/customer.response';
import { LicenseUser } from 'src/app/_api/responses/license-user.response';
import { License } from 'src/app/_api/responses/license.response';
import { LicensedUsers } from 'src/app/_api/responses/licensed-users';
import { Subscription } from 'src/app/_api/responses/subscription.response';
import { PrimeManager } from 'src/app/_modal/pmodal.manager';
import { UserImportService } from 'src/app/_portal/data/account.service';
import { DataStore } from 'src/app/_portal/data/data.store';
import { SearchByCustomerComponent } from 'src/app/management/search/search-by-customer/search-by-customer.component';
import { SearchByEmailComponent } from 'src/app/management/search/search-by-email/search-by-email.component';
import { AddCustomerComponent } from '../customer/add-customer/add-customer.component';
import { FeatureRequest, FeatureUpsertRequest } from '../feature/feature-request.model';
import { NewUserModalComponent } from './new-user-modal.component';
import { NoAdminErrorMessageComponent } from './no-Admin-error-msg-component';
import { RemoveLicenseModalComponent } from './remove-license-modal/remove-license-modal.component';
import { RemoveUserModalComponent } from './remove-user-modal.component';
import { CustomerService } from './service/customer.service';
// import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

interface SelectedUsers {
    [id: string]: boolean;
}

// TODO: Refactor paging out into its own component
@Component({
    templateUrl: 'user-list.component.html'
})
export class UserListComponent extends SubscriberEntity implements OnInit {
    public customer?: Customer[];
    public userList: List<any> = new List<LicensedUsers>([]);
    public selectedUserIds: SelectedUsers = {};
    public hasSelectedAll = false;
    public showActionList = false;
    public bulkImportExists = true;
    public searchText = '';
    public pageStart?: number;
    public pageEnd?: number;
    public availablePages?: number[];
    public emptyRows?: number[];
    public currentPage = 0;
    public pageSize = 25;
    public disable = true;
    public featuresUpsertRequest: FeatureUpsertRequest = {
        Name: '',
        Expiration: '',
        UserLimit: 10
    };
    public deletelicense: LicenseUser[] = [];
    public licenserequests!: FeatureRequest;
    public listStatus: string | undefined = `Loading...`;
    public columns: { name: string, property: string, get: (x: any) => any }[] = [
        { name: 'Feature', property: 'Feature', get: (x: Subscription) => x.Feature },
        { name: 'Subscription Level', property: 'Level', get: (x: Subscription) => x.Level },
        { name: 'Expiration', property: 'End', get: (x: Subscription) => x.End },
        { name: 'Total Licenses', property: 'UserLimit', get: (x: License) => x.UserLimit },
        { name: 'Unassigned Licenses', property: 'ActiveUsers', get: (x: License) => x.ActiveUsers }
    ];
    public allowBulkActions = true;
    public partnerName = '';
    public totalLicenses = 0;
    public activeusers = 0;
    public unassignedLicenses = 0;
    public selectedCustomer!: Customer;
    public licenseid!: string;
    public licensedUsers: LicensedUsers[] = [];
    public order = 'desc';
    public Admin = true;
    @ViewChild('noAdminErrorMessageComponent') public noAdminErrormsgblock: NoAdminErrorMessageComponent | undefined;
    constructor(
        public dataStore: DataStore,
        protected userImportService: UserImportService,
        protected modalManager: ModalManager,
        protected spinner: Spinner,
        protected userClient: UserClient,
        protected primeManager: PrimeManager,
        protected customerDetails: CustomerService,
        private modalService: NgbModal

    ) {
        super();
    }

    public ngOnInit(): void {
        this.setCustomer();
    }

    public sortUsersBy(value: string): void {
        this.order = value;
        if (value === 'asc') {
            this.licensedUsers.sort((a, b) => (a.Email > b.Email) ? 1 : ((b.Email > a.Email) ? -1 : 0));
        } else if (value === 'desc') {
            this.licensedUsers.sort((a, b) => (a.Email > b.Email) ? -1 : ((a.Email < b.Email) ? 1 : 0));
        }

    }

    public searchByCustomer(): void {
        const customerlist = this.customer;
        const modalRef = this.modalManager
            .open<any, SearchByCustomerComponent>(SearchByCustomerComponent, { size: 'lg', mayDismiss: false });
        if (this.modalManager.modalRef) {
            //this.modalManager.modalRef.componentInstance.customer = customerlist;
            this.modalManager.modalRef.componentInstance.disable = true;
        }
        modalRef.once((result: any) => {
            if (result && result.toString() !== 'close') {
                this.customerDetails.getSelectedCustomerDetails(result);
                this.setUserCustomerLog(result.Id);
            }
        });
    }

    public searchByEmail(): void {
        const customerlist = this.customer;
        const modalRef = this.modalManager
            .open<any, SearchByEmailComponent>(SearchByEmailComponent, { size: 'lg', mayDismiss: false });
        if (this.modalManager.modalRef) {
            //this.modalManager.modalRef.componentInstance.customer = customerlist;
            this.modalManager.modalRef.componentInstance.disable = true;
        }
        modalRef.once((result: any) => {
            if (result && result.toString() !== 'close') {
                this.customerDetails.getSelectedCustomerDetails(result);
                this.setUserCustomerLog(result.Id);
            }
        });
    }

    public addUser(): void {
        if (this.disable) {
            return;
        }
        this.modalManager
            .open<LicenseUser[], NewUserModalComponent>(NewUserModalComponent, { size: 'lg', mayDismiss: false })
            .once((result: any[]) => {
                if (result && result.toString() !== 'close' && result.length > 0) {
                    const featuresUpsertRequests: FeatureUpsertRequest[] = [];
                    const a = result[0].features;
                    let i = 0;
                    let activeUsers = 0;
                    // console.log(result[0]);

                    // console.log(this.selectedCustomer);









                    // this.selectedCustomer.Licenses.forEach((x, index) => {
                    //     if (x.Features.length === a.length) {
                    //         if (x.Features[i] === a[i] && x.Features[i] !== undefined && a[i] !== undefined) {
                    //             // this.licenseid = x.Id;
                    //             i = i + 1;
                    for (const res of result) {
                        res.LicenseId = this.selectedCustomer.Licenses.filter(s => res.features.find((d: any) => d === s.Features[0])).map(lid => lid.Id);
                    }
                    // }
                    // } else {
                    //     const d = new Date();
                    //     const year = d.getFullYear();
                    //     const month = d.getMonth();
                    //     const day = d.getDate();
                    //     const c = new Date(year + 1, month, day);
                    //     const date = new Date();
                    //     const ngbDateStruct = { day: date.getUTCDay(), month: date.getUTCMonth(), year: date.getUTCFullYear() + 1 };
                    //     this.featuresUpsertRequest = {
                    //         Name: a[index],
                    //         UserLimit: activeUsers = this.unassignedLicenses,
                    //         Expiration: this.selectedCustomer.Licenses[index].FeatureExpirationDate[index]
                    //     };
                    //     if (this.featuresUpsertRequest.Name !== undefined) {
                    //         featuresUpsertRequests.push(this.featuresUpsertRequest);
                    //     }
                    //     this.licenserequests = {
                    //         Id: '',
                    //         Application: this.selectedCustomer.Licenses[index].ApplicationShortName,
                    //         CustomerId: this.selectedCustomer.Licenses[index].CustomerId,
                    //         Expiration: c,
                    //         UserLimit: activeUsers = this.unassignedLicenses,
                    //         Reseller: undefined,
                    //         ResellerLicenseId: undefined,
                    //         features: featuresUpsertRequests
                    //     };
                    // }
                    // });
                    if (result.length > 0) {
                        this.spinner.begin();
                        const request = this.dataStore.upsertLicenseUsers(result);
                        request.onceDefined((x) => {
                            this.setCustomer();
                        },
                            (error) => {
                                // console.log(error);
                            }, () => {
                                this.spinner.end();
                            });
                    }
                }
            });
    }

    public RemoveLicense(): void {
        this.modalManager
            .open<any[], RemoveLicenseModalComponent>(RemoveLicenseModalComponent, { size: 'lg', mayDismiss: false })
            .subscribe((result: any[]) => {
                if (result && result.toString() !== 'close') {
                    this.deletelicense = [];
                    result.forEach((element) => {
                        const licenseUsers: LicenseUser[] = [];
                        const licenseUser: LicenseUser = {
                            UserId: element.userid,
                            Email: '',
                            FirstName: '',
                            LastName: '',
                            JobTitle: '',
                            Phone: '',
                            IsAdmin: false,
                            Claims: [],
                            Roles: [],
                            LicenseId: element.License,
                            IsActive: true,
                            features: element.actualsubscription
                        };
                        this.deletelicense.push(licenseUser);
                    });

                    this.spinner.begin();
                    const request = this.dataStore.removeLicense(this.deletelicense);
                    this.spinner.begin();
                    request.onceDefined((x) => {
                        this.setCustomer();
                    },
                        (error) => {
                            this.modalManager.error('Something Went wrong... please re-try or contact Admin');
                            // console.log(error);
                        }, () => {
                            this.spinner.end();
                        });
                }
            });
    }

    public removeLicenseUser(): void {
        if (this.disable) {
            return;
        }
        if (!(this.selectedCustomer && this.selectedCustomer.Licenses.length > 0)) {
            return;
        }
        const modalRef = this.modalManager
            .open<LicenseUser[], RemoveUserModalComponent>(RemoveUserModalComponent, { size: 'lg', mayDismiss: false });
        if (this.modalManager.modalRef) {
            this.modalManager.modalRef.componentInstance.licensedUsers = this.selectedCustomer;
            this.modalManager.modalRef.componentInstance.callbackFunction = this.setCustomer();
        }
    }

    public customerUpsert(isAdd: boolean): void {
        const modalRef = this.modalManager
            .open<any, AddCustomerComponent>(AddCustomerComponent, { size: 'lg', mayDismiss: false });
        if (this.modalManager.modalRef) {
            if (!isAdd) {
                this.modalManager.modalRef.componentInstance.selectedCustomer = this.selectedCustomer;
                this.modalManager.modalRef.componentInstance.licensedUsers = this.licensedUsers;
                this.modalManager.modalRef.componentInstance.title = 'Customer Detail';
            } else {
                this.modalManager.modalRef.componentInstance.title = 'Customer Detail';
                this.modalManager.modalRef.componentInstance.licensedUsers = this.licensedUsers;
                this.modalManager.modalRef.componentInstance.isAdd = isAdd;
            }
        }
        modalRef.once((result: any) => {
            if (result && result.toString() !== 'close') {
                if (result) {
                    const request = this.dataStore.upsertCustomerAsync(result.customer);
                    this.spinner.begin();
                    request.once((x: string) => {
                        const licenseObserversList: Observable<any>[] = [];
                        result.licenses.forEach((license: FeatureRequest) => {
                            license.CustomerId = x;
                            licenseObserversList.push(this.dataStore.upsertLicense(license, false));
                        });
                        // const featureRequest = result.licenses[0] as FeatureRequest;
                        // featureRequest.CustomerId = x;
                        let licenseId: any = null;
                        this.spinner.begin();
                        forkJoin(licenseObserversList)
                            .once((data) => {
                                this.spinner.begin();
                                licenseId = data[0];
                                const licensedUsers = result.licenseUsers as LicensedUserUpsertRequest[];
                                licensedUsers.forEach((y) => {
                                    if (!y.LicenseId) {
                                        y.LicenseId = licenseId;
                                    }
                                });
                                licensedUsers.forEach(d => d.LicenseId = [d.LicenseId] as any)
                                let createdUsers: any;
                                this.dataStore.upsertLicensedUsers(licensedUsers, false).
                                    once((users) => {
                                        createdUsers = users;
                                        this.saveCustomerAdmins(licensedUsers, users);
                                    }, () => { },
                                        () => {
                                            this.spinner.end();
                                            if (isAdd) {
                                                this.setUserCustomerLog(x);
                                            }

                                            this.dataStore.setCustomer(x);

                                        });
                            }, () => { }, () => {
                                this.spinner.end();
                            });
                    },
                        () => { }, () => {
                            this.spinner.end();
                        });
                }
            }
        });
    }

    public saveCustomerAdmins(licensedUsers: LicensedUserUpsertRequest[], users: any): void {
        const customerAdmins: CustomerAdminRequest[] = [];
        if (users) {
            for (const [key, value] of Object.entries(users)) {
                const licenseUser = licensedUsers.filter((licensedUser: LicensedUserUpsertRequest) => {
                    return licensedUser.Email === key;
                })[0];
                const customerAdmin: CustomerAdminRequest = {
                    ApplicationShortName: 'Nav',
                    IsAdmin: licenseUser.IsAdmin,
                    UserId: value as string
                };
                customerAdmins.push(customerAdmin);
            }
            const customerAdminsApi = this.dataStore.UpsertCustomerAdmin(customerAdmins);
            this.spinner.while(customerAdminsApi);
        }
    }
    public setCustomer(): void {
        this.dataStore.customer.pipe(takeUntil(this.destroyed)).whileDefined((result) => {
            this.disable = false;
            console.log(result);

            this.selectedCustomer = result;

            const subscriptionIds = this.selectedCustomer.Subscriptions;
            this.selectedCustomer.Subscriptions = subscriptionIds.sort(
                (a, b) => (a.LicenseId > b.LicenseId) ? 1 : ((b.LicenseId > a.LicenseId) ? -1 : 0));
            this.licensedUsers = [];
            if (result.Licenses) {
                result.Licenses.forEach((license) => {
                    this.partnerName = license.Reseller;
                    this.totalLicenses = this.totalLicenses > 0 ? license.UserLimit : this.totalLicenses + license.UserLimit;
                    this.activeusers = this.activeusers + parseInt(license.ActiveUsers.toString(), 10);
                    license.Users.forEach((user) => {
                        result.Subscriptions.forEach((sub) => {
                            if (license.Id === sub.LicenseId) {
                                const _licenseUser: LicensedUsers = {
                                    Id: user.LicenseId,
                                    UserId: user.UserId,
                                    Email: user.Email,
                                    FirstName: user.FirstName,
                                    LastName: user.LastName,
                                    Roles: user.Roles,
                                    Feature: sub.Feature,
                                    Level: sub.Level,
                                    Start: sub.Start,
                                    End: sub.End
                                };
                                this.licensedUsers.push(_licenseUser);
                            }
                        });
                        this.licensedUsers.sort((a, b) => (a.Email > b.Email) ? -1 : ((a.Email < b.Email) ? 1 : 0));
                    });
                });
                this.unassignedLicenses = this.totalLicenses - this.activeusers;
            }
        });
        this.dataStore.customerList.pipe(takeUntil(this.destroyed)).whileDefined((customer) => {
            this.customer = customer;

        });
        this.dataStore.emailList.pipe(takeUntil(this.destroyed)).whileDefined((customer) => {
            this.customer = customer;

        });
        this.dataStore.loginUserorAdminType$.pipe(takeUntil(this.destroyed)).whileDefined((data) => {
            if (data) {
                if (!this.dataStore.isApplicationAdmin) {
                    this.modalService.open(this.noAdminErrorMessage(this.Admin));
                }
            }
        });

    }
    public setUserCustomerLog(customerId: string): void {
        if (this.dataStore.user && this.dataStore.user.Id) {
            const userCustomerLog: UserCustomerLogModel = {
                UserId: this.dataStore.user.Id,
                CustomerId: customerId
            };
            this.dataStore.upsertUserCustomerLog(userCustomerLog);
        }
    }
    public isAdmin(roles: string[]): boolean {
        if (roles && roles.length > 0) {
            return roles.includes('Nav.CustomerAdmin');
        } else {
            return false;
        }
    }
    public noAdminErrorMessage(admin: boolean): void {
        const modalRef = this.modalManager
            .open<any, NoAdminErrorMessageComponent>(NoAdminErrorMessageComponent, { size: 'sm', mayDismiss: false });
        if (this.modalManager.modalRef && this.modalManager.modalRef.componentInstance.noAdminErrorMessageComponent) {
            this.modalManager.modalRef.componentInstance.noAdminErrorMessageComponent.disable = true;
        }
        this.Admin = admin;
    }

    public ExportUsers() {

        var licenseUsers = [{ 'First Name': '', 'Last Name': '', 'Email': '' }]


        if (this.licensedUsers.length > 0) {
            licenseUsers = this.licensedUsers.map(item => ({ 'First Name': item.FirstName, 'Last Name': item.LastName, 'Email': item.Email }));
        }

        let csvContent = this.saveDataInCSV(licenseUsers);

        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvContent);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'Licensed-User-List ' + '.csv';
        hiddenElement.click();
    }


    public saveDataInCSV(data: Array<any>): string {

        if (data.length == 0) {
            return '';
        }

        let propertyNames = Object.keys(data[0]);
        let rowWithPropertyNames = propertyNames.join(',') + '\n';

        let csvContent = rowWithPropertyNames;

        let rows: string[] = [];

        data.forEach((item) => {
            let values: string[] = [];

            propertyNames.forEach((key) => {
                let val: any = item[key];

                if (val !== undefined && val !== null) {
                    val = new String(val);
                } else {
                    val = '';
                }
                values.push(val);
            });
            rows.push(values.join(','));
        });
        csvContent += rows.join('\n');

        return csvContent;
    }


    // public mapSubscriptions(subscriptionData: Subscription[]): Subscription[] {
    //     let cocProSubscription: Subscription;
    //     let ineproSubscription: Subscription;
    //     let finalSubscription: Subscription[] = [];;
    //     let cocFeatures = ['CocBasic',
    //         'CocPro',
    //         'CocEnterprise',
    //         'UsiBasic',
    //         'UsiPro',
    //         'UsiEnterprise'
    //     ]


    //     let ineFeatures = ['IneBasic', 'InePro', 'IneEnterprise', 'IniBasic', 'IniPro', 'IniEnterprise', 'BtaPro', 'BtaEnterprise']
    //     let data = subscriptionData;
    //     let existingcocfeatures = data.filter(feat => cocFeatures.find(name => name == feat.actualsubscriptionname));
    //     if (existingcocfeatures.length > 0) {
    //         let tempCocSubscription = existingcocfeatures.find(s => s.actualsubscriptionname === 'CocPro' || s.actualsubscriptionname === 'CocEnterprise' || s.actualsubscriptionname === 'CocBasic');
    //         if (tempCocSubscription) {
    //             cocProSubscription = tempCocSubscription;
    //         } else {
    //             cocProSubscription = existingcocfeatures[0];
    //             cocProSubscription.Feature = 'U.S. Cost Of Capital';
    //         }

    //         finalSubscription.push(cocProSubscription);

    //     }

    //     let existinginefeatures = data.filter(feat => ineFeatures.find(name => name == feat.actualsubscriptionname));
    //     if (existinginefeatures.length > 0) {
    //         let tempCocSubscription = existinginefeatures.find(s => s.actualsubscriptionname === 'InePro' || s.actualsubscriptionname === 'IneEnterprise' || s.actualsubscriptionname === 'IneBasic');

    //         if (tempCocSubscription) {
    //             cocProSubscription = tempCocSubscription;
    //         } else {
    //             cocProSubscription = existinginefeatures[0];
    //             cocProSubscription.Feature = 'U.S. Cost Of Capital';
    //         }

    //         finalSubscription.push(cocProSubscription);

    //     }

    // }


}
