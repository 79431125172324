import * as moment from 'moment';

import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Spinner } from '@concurrency/angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerApiModel, CustomerEmailModel, CustomerUpsertRequest } from 'src/app/_api/requests/customer-upsert.request';
import { LicenseUpsertRequest } from 'src/app/_api/requests/license-upsert.request';
import { LicensedUserUpsertRequest } from 'src/app/_api/requests/licensed-user-upsert';
import { UserUpsertRequest } from 'src/app/_api/requests/user-upsert.request';
import { Customer } from 'src/app/_api/responses/customer.response';
import { License } from 'src/app/_api/responses/license.response';
import { LicensedUsers } from 'src/app/_api/responses/licensed-users';
import { Subscription } from 'src/app/_api/responses/subscription.response';
import { DataStore } from 'src/app/_portal/data/data.store';
import { createGuid } from '../../custom/guid';
import { CommonDate } from '../../feature/date-struct';
import { FeatureUpsertRequest } from '../../feature/feature-request.model';
import { CustomerValidationService } from '../customer-validation.service';
@Component({
    templateUrl: 'add-customer.component.html'
})
export class AddCustomerComponent implements OnInit, OnDestroy {
    @HostBinding('class') public classes = 'modal-scroll';
    public customer?: Customer;
    public title?: string;
    public selectedCustomer?: Customer;
    public licensedUsers?: LicensedUsers[];
    public isAdd?: boolean;
    public selection?: CustomerApiModel | null;
    public moment = moment;
    public customerForm: FormGroup = {} as FormGroup;
    public submitted = false;
    public licenseId?: string;
    public customerFormData?: Customer;
    public featureFormData?: Subscription[];
    public adminUserFormData?: LicensedUsers[];
    public hideactions = false;
    public isEditIntialValue = false;
    public onEditIntialValue: any = null;

    constructor(
        private formBuilder: FormBuilder,
        public activeModal: NgbActiveModal,
        public dataStore: DataStore,
        public spinner: Spinner,
        public customerValidationService: CustomerValidationService
    ) { }

    public ngOnInit(): void {
        this.customerValidationService.resetAll();
        this.customerForm = this.formBuilder.group({
            Licenses: new FormArray([])
        });
        this.customerForm.valueChanges.subscribe((x) => {
            if (!this.isAdd && this.customerForm.controls.Customer
                && this.customerForm.controls.Features
                && this.customerForm.controls.Licenses
                && this.customerForm.controls.Users
                && this.customerForm.valid
                && !this.isEditIntialValue
            ) {
                this.onEditIntialValue = Object.assign(this.customerForm.value);
                this.isEditIntialValue = true;
                this.selection = null;
            } else if (this.isEditIntialValue && !this.isAdd) {
                if (JSON.stringify(this.onEditIntialValue) === JSON.stringify(this.customerForm.value) &&
                    this.selectedCustomer && this.selectedCustomer.Subscriptions.length > 0
                    && this.licensedUsers && this.licensedUsers.length > 0) {
                    this.selection = null;
                } else {
                    this.selection = this.customerForm.valid ? (this.customerForm.value) : null;
                }

            } else if (this.isAdd) {
                this.selection = this.customerForm.valid ? (this.customerForm.value) : null;
            }
        });
        if (!this.isAdd && this.selectedCustomer) {
            this.customerFormData = this.selectedCustomer;
            this.featureFormData = this.selectedCustomer.Subscriptions;
            this.adminUserFormData = this.licensedUsers;
        }
        this.customerValidationService.customErrors = [];
    }
    get main(): {
        [key: string]: AbstractControl;
    } { return this.customerForm.controls; }

    public onSubmit(): void {
        this.submitted = true;
        this.customerValidationService.clearErrors();
        this.setValidationMessages();
        if (this.customerValidationService.customErrors.length > 0) {
            this.customerValidationService.allowSubmit = false;
            return;
        }
        // stop here if form is invalid
        if (this.customerForm.invalid) {
            return;
        }
        this.apiModelConvertion();
        if (this.selection && this.selection.users) {
            this.verifyBusinessValidations(this.selection.users);
        }

    }
    public verifyBusinessValidations(users: UserUpsertRequest[]): void {
        let customerEmailModels: CustomerEmailModel[] = [];
        if (CustomerValidationService.customerEmailModels.length > 0) {
            customerEmailModels = CustomerValidationService.customerEmailModels;
        }
        CustomerValidationService.customerEmailModels = [];
        users.forEach((lu) => {
            const customerEmailModel: CustomerEmailModel = {
                CustomerId: this.customerForm.value.Customer.Id,
                Email: lu.Email,
                IsEmailWithOtherCustomer: true,
                ResellerName: this.customerForm.value.Customer.Partner === 'Kroll'
                    ? 'Internal' : this.customerForm.value.Customer.Partner,
                IsCustomerWithOtherPartner: true
            } as CustomerEmailModel;
            CustomerValidationService.customerEmailModels.push(customerEmailModel);
        });
        if (customerEmailModels.length > 0 && CustomerValidationService.customerEmailModels.length > 0) {
            let differenceCount = 0;
            CustomerValidationService.customerEmailModels.forEach((x) => {
                if (customerEmailModels.filter((m) => m.Email === x.Email).length === 0) {
                    differenceCount += 1;
                }
            });
            if (differenceCount > 0) {
                this.customerValidationService.allowSubmit = false;
            }
        }
        this.saveAfterBusinessValidations(CustomerValidationService.customerEmailModels);
    }
    public saveAfterBusinessValidations(customerEmailModels: CustomerEmailModel[]): void {
        // this.spinner.while(request);v
        this.customerValidationService.verifyPartners(customerEmailModels).once((m: CustomerEmailModel[]) => {
            // let request = this.customerValidationService.getData();
            // let request = this.dataStore.IsEmailsWithCustomer(CustomerValidationService.customerEmailModels);
            // request.once((m: CustomerEmailModel[]) => {
            CustomerValidationService.customerEmailModels = m;
            this.setValidationMessages(m);
            if (this.customerValidationService.customErrors.length > 0) {
                this.selection = null;
                return;
            }
            if (!(this.customerValidationService.customerAdminErrors.length)) {
                this.customerValidationService.allowSubmit = true;
            }
            if (!(this.customerValidationService.customErrors.length > 0) &&
                this.customerValidationService.allowSubmit && this.selection !== null) {
                this.apiModelConvertion();
                if (this.customerValidationService.customerAdminErrors.length) {
                    const deleteUsers = this.customerValidationService.deleteLicenseUsers();
                    this.spinner.while(deleteUsers);
                    deleteUsers.once(() => {
                        this.activeModal.close(this.selection);
                    });
                } else {
                    this.activeModal.close(this.selection);
                }
            }
            this.customerValidationService.allowSubmit = true;
        });
    }
    public setValidationMessages(customerEmailModels?: CustomerEmailModel[]): void {
        for (let index = 0; index < this.customerForm.value.Users.length; index++) {
            const user = this.customerForm.value.Users[index];
            const controlArray = this.customerForm.controls.Users as FormArray;
            const control = controlArray.controls[index] as FormGroup;
            this.customerValidationService.setCustomValidationsForUser(control, user, customerEmailModels);
        }
        if (customerEmailModels && customerEmailModels.length) {
            this.customerValidationService.addCutomerErrors(customerEmailModels);
        }

    }
    public apiModelConvertion(): any {
        const Features: FeatureUpsertRequest[] = [];
        const Licenses: LicenseUpsertRequest[] = [];
        const groupedLicenses: LicenseUpsertRequest[] = [];
        this.customerForm.getRawValue().Features.forEach((x: any) => {
            const feature: FeatureUpsertRequest = {
                Name: x.Feature + x.Level,
                Expiration: CommonDate.fromStruct(x.End).asMoment().toDate(),
                UserLimit: x.UserLimit !== 0 ? x.UserLimit : null
            };
            Licenses.push(this.addLicenseFeature(x.LicenseId, feature));
            // Set License Expiration Date , User Limit

        });

        Licenses.forEach((license) => {
            if (license.Id) {
                if (groupedLicenses.filter((l) => l.Id === license.Id).length <= 0) {
                    if (Licenses.filter((l) => l.Id === license.Id).length > 0) {
                        let licenseExpirationDate: any = null;
                        let licenseUserLimit: any = 0;
                        const filteredLicenseFeatures: FeatureUpsertRequest[] = [];
                        Licenses.filter((l) => l.Id === license.Id).forEach((filteredLicense) => {
                            filteredLicense.Features.forEach((feature) => {
                                if (filteredLicenseFeatures.filter((f) => f === feature).length <= 0) {
                                    filteredLicenseFeatures.push(feature);
                                }
                                if (!licenseExpirationDate) {
                                    licenseExpirationDate = feature.Expiration;
                                } else {
                                    if (new Date(licenseExpirationDate).setHours(0, 0, 0, 0) <
                                        new Date(feature.Expiration as Date).setHours(0, 0, 0, 0)) {
                                        licenseExpirationDate = feature.Expiration;
                                    }
                                }
                                if (!licenseUserLimit) {
                                    licenseUserLimit = feature.UserLimit;
                                } else {
                                    if (feature.UserLimit && licenseUserLimit < feature.UserLimit) {
                                        licenseUserLimit = feature.UserLimit;
                                    }
                                }
                            });
                        });
                        Licenses.filter((l) => l.Id === license.Id).forEach((feature) => {
                            feature.Expiration = licenseExpirationDate,
                                feature.UserLimit = licenseUserLimit;
                        });
                        const groupedLicense = Licenses.filter((l) => l.Id === license.Id)[0];
                        groupedLicense.Expiration = licenseExpirationDate;
                        groupedLicense.UserLimit = licenseUserLimit;
                        groupedLicense.Features = filteredLicenseFeatures;
                        groupedLicenses.push(groupedLicense);
                    }
                }

            } else {
                groupedLicenses.push(license);
            }
        });
        const deletedLicences: any[] = [];
        if (this.selectedCustomer && (!this.isAdd)) {
            this.selectedCustomer.Licenses.filter((item: License) => {
                if (item.Id && this.selectedCustomer &&
                    (Licenses.filter((f) => f.Id === item.Id).length <= 0 && item.Features.length > 0)) {
                    deletedLicences.push(item);
                }
            });
        }
        deletedLicences.forEach((item: any) => {
            const deletedLicence = {
                CustomerId: item.CustomerId,
                Application: 'Nav',
                Id: item.Id,
                Reseller: item.Reseller,
                ResellerLicenseId: item.ResellerLicenseId,
                Expiration: item.Expiration,
                UserLimit: item.UserLimit,
                Features: []
            };
            groupedLicenses.push(deletedLicence);
        });
        const Users: UserUpsertRequest[] = this.customerForm.value.Users as UserUpsertRequest[];
        const licensedUsers: LicensedUserUpsertRequest[] = [];
        Users.forEach((x: any) => {
            const licensedUser = {
                ...x,
                IsActive: true,
                IsAdmin: (x.Roles.includes('Nav.CustomerAdmin')
                    || x.Roles.includes('Nav.GlobalAdmin') ||
                    x.Roles.includes('Nav.ThirdPartyAdmin')) ? true : false,
                LicenseId: x.LicenseId ? x.LicenseId : ''
            };
            licensedUsers.push(licensedUser);
        });
        const customerApiModel: CustomerApiModel = {
            customer: this.customerForm.value.Customer as CustomerUpsertRequest,
            licenses: groupedLicenses,
            features: Features,
            users: Users,
            partner: this.customerForm.value.Customer.Partner === 'Kroll' ? 'Internal' : this.customerForm.value.Customer.Partner,
            licenseUsers: licensedUsers
        };
        this.selection = customerApiModel;
    }
    public addLicenseFeature(licenseId: string, feature: FeatureUpsertRequest): LicenseUpsertRequest {
        let licenseUpsertRequest: LicenseUpsertRequest = {} as LicenseUpsertRequest;
        if (licenseId) {
            if (this.selectedCustomer && (!this.isAdd)) {
                if (this.selectedCustomer.Licenses.length > 0) {
                    let resellarId = '';
                    if (this.selectedCustomer.Licenses.filter((l) => l.Id === licenseId).length > 0) {
                        if (this.selectedCustomer.Licenses.filter((l) => l.Id === licenseId)[0].ResellerLicenseId) {
                            resellarId = this.selectedCustomer.Licenses.filter((l) => l.Id === licenseId)[0].ResellerLicenseId;
                        } else {
                            resellarId = createGuid();
                        }
                    }
                    licenseUpsertRequest = {
                        CustomerId: this.selectedCustomer.Id,
                        Application: 'Nav',
                        Id: licenseId,
                        Reseller: this.customerForm.value.Customer.Partner === 'Kroll' ? 'Internal' :
                            this.customerForm.value.Customer.Partner,
                        ResellerLicenseId: resellarId,
                        Expiration: feature.Expiration ? new Date(feature.Expiration) : new Date(),
                        UserLimit: feature.UserLimit,
                        Features: [feature]
                    };
                }
            }
        } else {
            licenseUpsertRequest = {
                CustomerId: '',
                Application: 'Nav',
                Id: '',
                Reseller: this.customerForm.value.Customer.Partner === 'Kroll' ? 'Internal' : this.customerForm.value.Customer.Partner,
                ResellerLicenseId: createGuid(),
                Expiration: feature.Expiration ? new Date(feature.Expiration) : new Date(),
                UserLimit: feature.UserLimit,
                Features: [feature]
            };
        }
        return licenseUpsertRequest;
    }
    public onChange(form: any): void {
        // reset the form value to the newly emitted form group value.
        this.customerForm = form;
    }
    public ngOnDestroy(): void {
        this.customerValidationService.resetAll();
    }
}
