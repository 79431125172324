import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Spinner } from '@concurrency/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { AdminErrors, CustomerEmailModel, CustomerError } from 'src/app/_api/requests/customer-upsert.request';
import { DataStore } from 'src/app/_portal/data/data.store';
import {
  isCustomerWithOtherPartner,
  isCustomerWithOtherPartnerValidator,
  isEmailWithOtherCustomer,
  isEmailWithOtherCustomerValidator,
  isValidEmail,
  isValidName,
  ValidateEmail,
  ValidateName
} from '../custom/custom-validators';

@Injectable({
  providedIn: 'root'
})
export class CustomerValidationService {
  public static customerEmailModels: CustomerEmailModel[] = [];
  public krollCustomerAdminErrors: string[] = [];
  public valueSourceCustomerAdminErrors: string[] = [];
  public customerAdminErrors: string[] = [];
  public customErrors: string[] = [];
  public allowSubmit = false;
  public data = new BehaviorSubject<CustomerEmailModel[] | undefined>(undefined);
  public customerErrors: AdminErrors = {} as AdminErrors;
  constructor(public dataStore: DataStore, public spinner: Spinner) {
    this.resetCustomerErrors();
  }
  public resetCustomerErrors(): void {
    this.customerErrors = {
      customerAdmin: {
        samePartnerError: {
          message: `The following user(s) are assigned to a different customer account.
                    Please contact <a href="mailto:costofcapital.support@kroll.com">
                    Cost of Capital Navigator Support Team.</a><br/><br/>`,
          listOfmails: []
        },
        otherPartnerError: {
          message: `The following user(s) are assigned to a different partner.
                    Please contact <a href="mailto:costofcapital.support@kroll.com">
                    Cost of Capital Navigator Support Team.</a> <br/><br/>`,
          listOfmails: []
        }
      },
      krollAdmin: {
        samePartnerError: {
          message: `The following user(s) are assigned to a different customer account.
                    Click Save to proceed or X at the top right to cancel.<br/><br/>`,

          listOfmails: []
        },
        otherPartnerError: {
          message: `The following user(s) are assigned to a different partner.
                    Click Save to proceed or X at the top right to cancel.<br/><br/>`,
          listOfmails: []
        }
      },
      valuSourceAdmin: {
        samePartnerError: {
          message: `The following user(s) are assigned to a different customer account.
                    Click Save to proceed or X at the top right to cancel. <br/><br/>`,

          listOfmails: []
        },
        otherPartnerError: {
          message: `The following user(s) are assigned to a different partner.
                    Please contact <a href="mailto:costofcapital.support@kroll.com">
                    Cost of Capital Navigator Support Team.</a> <br/><br/>`,
          listOfmails: []
        }
      }
    } as AdminErrors;
  }

  public isCustomerValidationFail(email: string): boolean {
    if (email && this.customerAdminErrors.filter((x) => x.indexOf(email) > -1).length > 0) {
      return true;
    } else {
      return false;
    }
  }
  public addCutomerErrors(customerEmailModels: CustomerEmailModel[]): void {
    this.resetCustomerErrors();
    if (this.dataStore.isNavigatorInternalAdmin) {
      this.customerErrors.krollAdmin = this.addAdminErrors(customerEmailModels, this.customerErrors.krollAdmin);
      if (this.customerErrors.krollAdmin.samePartnerError.listOfmails.length > 0) {
        this.addCustomerAdminError(this.customerErrors.krollAdmin.samePartnerError.message);
      }
      if (this.customerErrors.krollAdmin.otherPartnerError.listOfmails.length > 0) {
        this.addCustomerAdminError(this.customerErrors.krollAdmin.otherPartnerError.message);
      }
    } else if (this.dataStore.isNavigatorValuSourceAdmin) {
      this.customerErrors.valuSourceAdmin = this.addAdminErrors(customerEmailModels, this.customerErrors.valuSourceAdmin);
      if (this.customerErrors.valuSourceAdmin.samePartnerError.listOfmails.length > 0) {
        this.addCustomerAdminError(this.customerErrors.valuSourceAdmin.samePartnerError.message);
      }
      if (this.customerErrors.valuSourceAdmin.otherPartnerError.listOfmails.length > 0) {
        this.addCustomerError(this.customerErrors.valuSourceAdmin.otherPartnerError.message);
      }
    } else if (this.dataStore.isNavigatorCustomerAdmin) {
      this.customerErrors.customerAdmin = this.addAdminErrors(customerEmailModels, this.customerErrors.customerAdmin);
      if (this.customerErrors.customerAdmin.samePartnerError.listOfmails.length > 0) {
        this.addCustomerError(this.customerErrors.customerAdmin.samePartnerError.message);
      }
      if (this.customerErrors.customerAdmin.otherPartnerError.listOfmails.length > 0) {
        this.addCustomerError(this.customerErrors.customerAdmin.otherPartnerError.message);
      }
    }

  }
  public addNoLicenceAvailableError(): void {
    this.addCustomerError('There are no available licenses to this feature.');
  }
  private addCustomerError(message: string): void {
    this.customErrors.push(this.removeCommaFromString(message));
  }
  private addCustomerAdminError(message: string): void {
    this.customerAdminErrors.push(this.removeCommaFromString(message));
  }
  private removeCommaFromString(message: string): string {
    return message.slice(0, message.length - 1);
  }
  public addAdminErrors(customerEmailModels: CustomerEmailModel[], addAdminError: CustomerError): CustomerError {
    customerEmailModels.forEach((x) => {
      if (x.IsEmailWithOtherCustomer && !x.IsCustomerWithOtherPartner) {
        addAdminError.samePartnerError.message += x.Email + ',';
        addAdminError.samePartnerError.listOfmails.push(x.Email);
      } else if (x.IsEmailWithOtherCustomer && x.IsCustomerWithOtherPartner) {
        addAdminError.otherPartnerError.message += x.Email + ',';
        addAdminError.otherPartnerError.listOfmails.push(x.Email);
      } else if (x.IsEmailWithOtherCustomer) {
        addAdminError.samePartnerError.message += x.Email;
        addAdminError.samePartnerError.listOfmails.push(x.Email);
      } else if (x.IsCustomerWithOtherPartner) {
        addAdminError.otherPartnerError.message += x.Email + ',';
        addAdminError.otherPartnerError.listOfmails.push(x.Email);
      }
    });
    return addAdminError;
  }
  public resetAll(): void {
    this.clearErrors();
    CustomerValidationService.customerEmailModels = [];
    this.allowSubmit = false;
  }
  public clearErrors(): void {
    this.customerAdminErrors = [];
    this.customErrors = [];
    this.resetCustomerErrors();
  }
  public verifyPartners(customerEmailModels: CustomerEmailModel[]): Observable<any> {
    // let request = this.getData();
    const request = this.dataStore.IsEmailsWithCustomer(CustomerValidationService.customerEmailModels);
    this.spinner.while(request);
    return request.once((m: CustomerEmailModel[]) => {
      CustomerValidationService.customerEmailModels = m;
      return m;
    });
  }
  public setCustomValidationsForUser(control: FormGroup, user: any, customerEmailModels?: CustomerEmailModel[]): void {
    control.controls.Email.clearValidators();
    control.controls.Email.updateValueAndValidity();
    control.controls.FirstName.clearValidators();
    control.controls.FirstName.updateValueAndValidity();
    control.controls.LastName.clearValidators();
    control.controls.LastName.updateValueAndValidity();
    if (user.Email !== '') {
      if (!isValidEmail(user.Email)) {
        this.customErrors.push(`'${user.Email}' is not valid email entry`);
        control.controls.Email.setValidators(ValidateEmail);
        control.controls.Email.updateValueAndValidity();
      }
      if (!this.dataStore.isNavigatorInternalAdmin
        && !this.dataStore.isNavigatorValuSourceAdmin) {
        if (customerEmailModels
          && customerEmailModels.length
          && isCustomerWithOtherPartner(user.Email, customerEmailModels)) {
          control.controls.Email.setValidators(isCustomerWithOtherPartnerValidator(customerEmailModels));
          control.controls.Email.updateValueAndValidity();
        } else if (customerEmailModels
          && customerEmailModels.length
          && isEmailWithOtherCustomer(user.Email, customerEmailModels)) {
          control.controls.Email.setValidators(isEmailWithOtherCustomerValidator(customerEmailModels));
          control.controls.Email.updateValueAndValidity();
        }
      }
      if (customerEmailModels
        && customerEmailModels.length
        && isCustomerWithOtherPartner(user.Email, customerEmailModels)
        && this.dataStore.isNavigatorValuSourceAdmin) {
        control.controls.Email.setValidators(isCustomerWithOtherPartnerValidator(customerEmailModels));
        control.controls.Email.updateValueAndValidity();
      }
    }
    if (user.FirstName !== '') {
      if (!isValidName(user.FirstName)) {
        this.customErrors.push(`'${user.FirstName}' is not valid, special chracters are not allowed`);
        control.controls.FirstName.setValidators(ValidateName);
        control.controls.FirstName.updateValueAndValidity();
      }

    }
    if (user.LastName !== '') {
      if (!isValidName(user.LastName)) {
        this.customErrors.push(`'${user.LastName}' is not valid, special chracters are not allowed`);
        control.controls.LastName.setValidators(ValidateName);
        control.controls.LastName.updateValueAndValidity();
      }
    }
  }

  public deleteLicenseUsers(): Observable<any> {
    let data: CustomerError = {} as CustomerError;
    if (this.dataStore.isNavigatorInternalAdmin) {
      data = this.customerErrors.krollAdmin;
    } else if (this.dataStore.isNavigatorValuSourceAdmin) {
      data = this.customerErrors.valuSourceAdmin;
    }
    let emails: string[] = [];
    data.samePartnerError.listOfmails.forEach((email) => { emails.push(email); });
    data.otherPartnerError.listOfmails.forEach((email) => { emails.push(email); });
    emails = Array.from(new Set(emails));
    return this.dataStore.DeleteCustomerLicenseUsers(emails);
  }
}
