import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Spinner } from '@concurrency/angular';
import { User } from './_api/responses/user.response';
import { DataStore } from './_portal/data/data.store';
import { UserStore } from './_portal/user/user.store';

@Component({
    templateUrl: 'home.component.html'
})
export class HomeComponent implements OnInit {
    public user: User | undefined;
    public isNavigatorUser = false;
    //  public isFamisPortalUser = false;

    constructor(
        public router: Router,
        private userStore: UserStore,
        private dataStore: DataStore,
        private spinner: Spinner
    ) { }

    public ngOnInit(): void {
        this.spinner.while(this.userStore.user);
        this.userStore.user.onceDefined((user) => {
            this.user = user;
            this.isNavigatorUser = user.Roles.some((x) => x === 'Nav.GlobalAdmin');
            //  this.isFamisPortalUser = user.Roles.some((x) => x === 'Portal.SiteAdmin');

            if (this.router.url !== '/dashboard') {
                if (this.isNavigatorUser) {
                    this.router.navigate(['dashboard']);
                } else {
                    // Note: Handles both the old and new keys
                    const recentCustomerId = localStorage.getItem('recentCustomerId') || localStorage.getItem('company');
                    if (recentCustomerId) {
                        this.dataStore.setCustomer(recentCustomerId);
                    } else {
                        // TODO: Bring back default customer
                    }
                }
            }
        });
    }

    public logout(): void {
        this.userStore.logoutCustomerOrEmployee();
    }

    public goto(route: string): void {
        this.router.navigate([route]);
    }

    public routeIsActive(path: string): boolean {
        return this.router.url === path;
    }
}
