import { Routes } from '@angular/router';
import { CanAccessGuard } from '../_portal/can-access.guard';
import { HasAuthGuard } from '../_portal/has-auth.guard';
import { HomeComponent } from '../home.component';
import { AccessDeniedComponent } from '../management/access-denied.component';
import { CustomerLoginComponent } from '../management/customer-login.component';
import { CustomerComponent } from '../management/customer/customer.component';
import { EmployeeLoginComponent } from '../management/employee-login.component';
import { DashboardComponent } from '../management/user/dashboard/dashboard.component';
import { UserListComponent } from '../management/user/user-list.component';

// Routing
export const routeConfig: Routes = [
    { path: 'employee', component: EmployeeLoginComponent },
    { path: 'customer-login', component: CustomerLoginComponent },
    { path: 'access-denied', component: AccessDeniedComponent, canActivate: [HasAuthGuard] },
    {
        path: '',
        component: HomeComponent,
        children: [
            {
                path: 'dashboard',
                component: DashboardComponent,
                children: [{ path: '', component: UserListComponent }]
                // children: [{ path: '', component: FeatureComponent }] To do in proper place
            },
            { path: '**', redirectTo: 'dashboard' }
        ],
        canActivate: [CanAccessGuard]
    },
    { path: '**', redirectTo: '' }
];
