import { NgModule } from '@angular/core';
import { CanAccessGuard } from './can-access.guard';
import { UserImportService } from './data/account.service';
import { DataStore } from './data/data.store';
import { HasAuthGuard } from './has-auth.guard';
import { UserStore } from './user/user.store';

const declarationsAndExports: any[] = [
];

@NgModule({
    declarations: [...declarationsAndExports],
    exports: [...declarationsAndExports],
    providers: [
        UserStore,
        DataStore,
        UserImportService,
        CanAccessGuard,
        HasAuthGuard
    ]
})
export class PortalModule { }
