import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContextualValue } from '@concurrency/core';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'src/app/_api/responses/subscription.response';
import { DataStore } from 'src/app/_portal/data/data.store';
import { CommonDate, DateStruct } from './date-struct';
import { months } from 'moment';

@Component({
    selector: 'feature',
    templateUrl: 'feature.component.html'
})
export class FeatureComponent implements OnInit {

    @Output() public formChange = new EventEmitter<any>();
    @Input() public formData!: FormGroup;
    @Input() public isAdd!: boolean;
    @Input() public subscription!: Subscription[];
    @Input() public model!: ContextualValue<any>;
    public maxExpDate: any;
    public minExpDate: any;
    public subscriptionsData: any[] = [];
    public featureForm!: FormGroup;
    // public features = [
    //     { value: 'Coc', name: 'U.S. Cost Of Capital' },
    //     { value: 'Usi', name: 'U.S. Industry Benchmarking' },
    //     { value: 'Ine', name: 'International Cost Of Capital' },
    //     { value: 'Ini', name: 'International Industry Benchmarking' },
    //     { value: 'Bta', name: 'Company-level Beta' }
    // ];

    public features = [
        { value: 'Coc', name: 'U.S. Cost Of Capital' },
        { value: 'Ine', name: 'International Cost Of Capital' },
    ];


    constructor(private datepickerConfig: NgbDatepickerConfig, private fb: FormBuilder, public dataStore: DataStore) { }

    public ngOnInit(): void {
        this.dateConfigration();
        const date = new Date();
        const ngbDateStruct = { day: date.getUTCDate(), month: date.getUTCMonth() + 1, year: date.getUTCFullYear() };
        this.datepickerConfig.maxDate = ngbDateStruct;
        this.featureForm = this.fb.group({
            Subscriptions: new FormArray([], [Validators.required])
        });
        if (this.isAdd) {
            this.addSubscription('');
        } else {
            if (this.subscription) {

                this.subscription.forEach((feature) => {

                    this.addSubscription('');
                    let featureValue = null;
                    const test = this.features.filter((x) => x.name === feature.Feature);
                    if (this.features.filter((x) => x.name === feature.Feature).length > 0) {
                        featureValue = this.features.filter((x) => x.name === feature.Feature)[0].value;
                    }
                    const subData = {
                        LicenseId: feature.LicenseId,
                        Type: feature.Type,
                        Feature: featureValue,
                        Level: feature.Level,
                        End: feature.End !== null ? CommonDate.fromString(feature.End) : null,
                        ProdType: feature.ProdType,
                        UserLimit: feature.UserLimit ? feature.UserLimit : null
                    };
                    this.subscriptionsData.push(subData);

                });
                this.subscriptions.setValue(
                    this.subscriptionsData
                );
            }
            // Edit Actions
        }

        this.addGroupToParent();
    }


    public dateConfigration() {
        const todayDate = new Date();
        const maxYear = todayDate.getUTCFullYear() + 5;
        this.minExpDate = { year: todayDate.getUTCFullYear(), month: 1, day: 1 }
        this.maxExpDate = { year: maxYear, month: 12, day: 31 }
    }
    get subscriptions(): FormArray { return this.featureForm.controls.Subscriptions as FormArray; }

    public addSubscription(e: any): void {

        let tempFormGroup = this.fb.group({
            LicenseId: [''],
            Type: [''],
            Feature: [{ value: '', disabled: this.isFeatureEditable() }, Validators.required],
            Level: [''],
            End: [{ value: '', disabled: this.isFeatureEditable() }, Validators.required],
            ProdType: [{ value: '', disabled: this.isFeatureEditable() }],
            UserLimit: [{ value: '', disabled: this.isFeatureEditable() }, Validators.required]
        });

        tempFormGroup.controls['Level'].setValue('Pro');
        this.subscriptions.push(tempFormGroup)



    }
    public removeSubscription(index: number): void {
        this.subscriptions.removeAt(index);
    }

    private addGroupToParent(): void {
        this.formData.addControl('Features', this.subscriptions);
        // this.onFormGroupChange.emit(this.formData);
    }
    public dateStringConvertion(date: any): any {
        return CommonDate.fromStruct(date).asDate();

    }
    public actualFeatureName(value: string): any {
        return this.features.filter((x) => x.value === value)[0] ? this.features.filter((x) => x.value === value)[0].name : null;
    }
    public isFeatureEditable(): boolean {
        return (!(this.dataStore.isNavigatorInternalAdmin || this.dataStore.isNavigatorValuSourceAdmin));
    }
}
