import { Component } from '@angular/core';
import { SubscriberEntity } from '@concurrency/angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserStore } from 'src/app/_portal/user/user.store';

@Component({
    templateUrl: 'no-Admin-error-msg-component.html',
    styleUrls: ['no-Admin-error-msg-component.scss']
})
export class NoAdminErrorMessageComponent extends SubscriberEntity {
    public disable = true;
    public noAdminErrorMessageComponent = true;
    constructor(
        private userStore: UserStore,
        public activeModal: NgbActiveModal

    ) { super(); }

    public logout(): void {
        this.userStore.logoutCustomerOrEmployee();
    }
}
