import { Injectable } from '@angular/core';
import { Spinner } from '@concurrency/angular';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { ApplicationClient } from 'src/app/_api/clients/application.client';
import { CustomerClient } from 'src/app/_api/clients/customer.client';
import { LicenseClient } from 'src/app/_api/clients/license.client';
import { UserClient } from 'src/app/_api/clients/user.client';
import {
    CustomerAdminRequest,
    CustomerEmailModel,
    CustomerUpsertRequest,
    UserCustomerLogModel
} from 'src/app/_api/requests/customer-upsert.request';
import { LicensedUserUpsertRequest } from 'src/app/_api/requests/licensed-user-upsert';
import { Application } from 'src/app/_api/responses/application.response';
import { Customer } from 'src/app/_api/responses/customer.response';
import { PortalAdminModel } from 'src/app/_api/responses/portal-admin.response';
import { User } from 'src/app/_api/responses/user.response';
import { FeatureRequest } from 'src/app/management/feature/feature-request.model';
import { LicenseUser } from '../../_api/responses/license-user.response';
import { UserStore } from '../user/user.store';
import { ModalManager } from '@concurrency/angular';

@Injectable()
export class DataStore {
    private _customerList = new BehaviorSubject<Customer[] | undefined>(undefined);
    private _emailList = new BehaviorSubject<Customer[] | undefined>(undefined);
    private _customer = new BehaviorSubject<Customer | undefined>(undefined);
    private _applications = new BehaviorSubject<Application[] | undefined>(undefined);

    public customerList = this._customerList.asObservable();
    public emailList = this._emailList.asObservable();
    public customer = this._customer.asObservable();
    public applications = this._applications.asObservable();
    public user: User | undefined;
    public loginUserType$ = new BehaviorSubject<PortalAdminModel | undefined>(undefined);
    public loginUserorAdminType$ = new BehaviorSubject<boolean>(false);
    public isNavigatorValuSourceAdmin = false;
    public isNavigatorInternalAdmin = false;
    public isNavigatorCustomerAdmin = false;
    public isApplicationAdmin = false;
    public pageCount = 1;
    public size = 100;
    public totalCount = 0;
    public pageStart = 0;
    public pageEnd = 0;
    public searchCustomerTerm = '';
    public searchEmailTerm = '';
    public isCustomerSearched = false;
    public isCustomerEmailSearched = false;
    public emailPageCount = 1;
    public emailPageStart = 0;
    public emailPageEnd = 0;
    public isInternal = false;

    constructor(
        private userStore: UserStore,
        private customerClient: CustomerClient,
        private licenseClient: LicenseClient,
        private applicationClient: ApplicationClient,
        private userClient: UserClient,
        protected spinner: Spinner,
        protected modalManager: ModalManager
    ) {
        this.userStore.user.onceDefined((user) => {
            this.user = user;
            this.setApplications();
            this.GetPortalAdmins('Nav');
            const setCustomerList = this.setCustomerList();
            this.spinner.while(setCustomerList);
            setCustomerList.once(() => {
                if (this.user && this.user.Id) {
                    const userCustomerLog = this.UserCustomerLog(this.user.Id);
                    this.spinner.while(userCustomerLog);
                    userCustomerLog.once((data: UserCustomerLogModel) => {
                        if (data && data.CustomerId) {
                            this.spinner.begin();
                            const setLoggedCustomer = this.setCustomer(data.CustomerId);
                            setLoggedCustomer.once(() => { this.spinner.end() });
                        } else {
                            if (this.user && this.user.Id) {
                                const request = this.customerClient.GetCustomersByUserId(this.user.Id);
                                this.spinner.begin();
                                request.once((customerIdsList) => {
                                    if (customerIdsList && customerIdsList.length > 0) {
                                        const updateUserCustomer = this.customerClient.UpsertUserCustomerAsync({ UserId: this.user?.Id, CustomerId: customerIdsList[0] } as UserCustomerLogModel)
                                        //this.spinner.while(updateUserCustomer);
                                        updateUserCustomer.once(() => {
                                            const setLoggedCustomer = this.setCustomer(customerIdsList[0]);
                                            //this.spinner.while(setLoggedCustomer);
                                            setLoggedCustomer.once(() => { this.spinner.end(); });
                                        })
                                    } else {
                                        this.spinner.end();
                                        if (this.isApplicationAdmin) {
                                            this.modalManager.error("No Customer Id found for logged in user")
                                        }
                                    }
                                });
                            }
                        }
                    });
                }
            });
        });

        this.loginUserType$.subscribe((data: PortalAdminModel | undefined) => {
            if (data && this.user) {
                if (this.user.Email.toLowerCase().includes('@duffandphelps.com') || this.user.Email.toLowerCase().includes('@kroll.com')) {
                    this.isInternal = true;
                }
                this.isNavigatorCustomerAdmin = data.IsLicenseAdmin
                    && this.user.Roles.indexOf('Nav.CustomerAdmin') >= 0;
                this.isNavigatorValuSourceAdmin = data.IsExternal && data.ResellerName === 'ValuSource';
                this.isNavigatorInternalAdmin = (!data.IsExternal)
                    && data.ResellerName === 'Internal'
                    && this.user.Roles.indexOf('Nav.GlobalAdmin') >= 0;
                this.isApplicationAdmin = data.IsApplicationAdmin;
                this.loginUserorAdminType$.next(true);
            }
        });
    }

    public clearCustomer(): void {
        this._customer.next(undefined);
    }

    public clearCustomerList(): void {
        this._customerList.next(undefined);
    }

    public clearEmailList(): void {
        this._emailList.next(undefined);
    }

    public setCustomer(id: string): Observable<void> {
        this.spinner.begin();
        const request = this.customerClient.find(id);
        //this.spinner.while(request);
        return request.once((customer) => {
            this._customer.next(customer);
            this.spinner.end();
        });
    }

    private setApplications(): Observable<void> {
        const request = this.applicationClient.list();
        return request.once((applications) => this._applications.next(applications));
    }

    public setCustomerList(): Observable<void> {
        this.pageStart = (this.pageCount - 1) * 100 + 1;
        this.pageEnd = (this.pageCount) * 100;
        const request = this.customerClient.list('Navigator', this.pageStart, this.searchCustomerTerm);
        return request.once((customerList) => this._customerList.next(customerList));
    }

    public setCustomerEmailList(): Observable<void> {
        this.emailPageStart = (this.emailPageCount - 1) * 100 + 1;
        this.emailPageEnd = (this.emailPageCount) * 100;
        const request = this.customerClient.emailList('Navigator', this.emailPageStart, this.searchEmailTerm);
        return request.once((emailList) => this._emailList.next(emailList));
    }

    public upsertLicenseUsers(licenseUsers: LicenseUser[]): Observable<void> {
        const request = this.licenseClient.upsert(licenseUsers);
        return request.once(() => this.refresh());
    }
    public upsertLicensedUsers(licenseUsers: LicensedUserUpsertRequest[], isCutomerRefresh: boolean = true): Observable<any> {
        const request = this.licenseClient.upsertUsers(licenseUsers);
        return request.once((data) => {
            if (isCutomerRefresh) {
                this.refresh();
            }
            return data;
        });
    }

    public removeLicenseUsers(licenseUsers: LicenseUser[]): Observable<void> {
        const request = this.licenseClient.remove(licenseUsers);
        return request.once(() => this.refresh());
    }

    public removeLicense(data: LicenseUser[]): Observable<void> {
        const request = this.licenseClient.removeLicense(data);
        return request.once(() => this.refresh());
    }

    public reinviteLicenseUsers(licenseUsers: LicenseUser[]): Observable<void> {
        const request = this.licenseClient.reinvite(licenseUsers);
        return request.once(() => this.refresh());
    }

    public refresh(): Observable<void> {
        return this.customer.onceDefined((customer) => this.setCustomer(customer.Id));
    }

    public upsertLicense(featureRequest: FeatureRequest, isCutomerRefresh: boolean = true): Observable<any> {
        const request = this.licenseClient.upsertLicense(featureRequest);
        return request.once((data) => {
            if (isCutomerRefresh) {
                this.refresh();
            }
            return data;
        });
    }
    public upsertCustomerAsync(customerUpsertRequest: CustomerUpsertRequest): Observable<any> {
        const request = this.customerClient.UpsertAsync(customerUpsertRequest);
        return request.once((data) => data);
    }
    public upsertUserCustomerLog(userCustomerLogModel: UserCustomerLogModel): Observable<any> {
        const request = this.customerClient.UpsertUserCustomerAsync(userCustomerLogModel);
        return request.once((data) => data);
    }
    public UserCustomerLog(id: string): Observable<any> {
        const request = this.customerClient.GetUserCustomerLog(id);
        return request.once((data) => data);
    }

    public GetPortalAdmins(name: string): Observable<any> {
        const request = this.userClient.GetPortalAdmins(name);
        return request.once((data) => {
            this.loginUserType$.next(data);
        });
    }
    public UpsertCustomerAdmin(customerAdminRequest: CustomerAdminRequest[]): Observable<any> {
        const request = this.customerClient.UpsertCustomerAdmin(customerAdminRequest);
        return request.once((data) => { });
    }
    public IsEmailsWithCustomer(customerEmailModels: CustomerEmailModel[]): Observable<any> {
        const request = this.customerClient.IsEmailsWithCustomer(customerEmailModels);
        return request.once((data) => data);
    }
    public DeleteCustomerLicenseUsers(emails: string[]): Observable<any> {
        const request = this.customerClient.DeleteCustomerLicenseUsers(emails);
        return request.once((data) => { });
    }
    public getPageResult(isLeft: boolean): Observable<void> {
        if (isLeft) {
            this.pageCount = this.pageCount <= 1 ? 1 : this.pageCount - 1;
        } else if (!isLeft) {
            this.pageCount = this.pageCount + 1;
        }
        return this.setCustomerList().once((data) => { });

    }
    public getEmailPageResult(isLeft: boolean): Observable<void> {
        if (isLeft) {
            this.emailPageCount = this.emailPageCount <= 1 ? 1 : this.emailPageCount - 1;
        } else if (!isLeft) {
            this.emailPageCount = this.emailPageCount + 1;
        }
        return this.setCustomerEmailList().once((data) => { });

    }
    public getCustomerList(): Observable<void> {
        this.pageCount = 1;
        this.searchCustomerTerm = '';
        return this.setCustomerList().once((data) => { });
    }
    public getEmailList(): Observable<void> {
        this.emailPageCount = 1;
        this.searchEmailTerm = '';
        return this.setCustomerEmailList().once((data) => { });
    }
    public searchCustomerbasedonFilter(searchCustomerTermInput: string): Observable<void> {
        this.pageCount = 1;
        this.searchCustomerTerm = searchCustomerTermInput;
        this.isCustomerSearched = true;
        return this.setCustomerList().once((data) => { });
    }
    public searchEmailbasedonFilter(searchEmailTermInput: string): Observable<void> {
        this.emailPageCount = 1;
        this.searchEmailTerm = searchEmailTermInput;
        this.isCustomerEmailSearched = true;
        return this.setCustomerEmailList().once((data) => { });
    }
}
