import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Customer } from 'src/app/_api/responses/customer.response';
import { DataStore } from 'src/app/_portal/data/data.store';
import { BusinessPhoneNumberValidator, LettersAndNumbersValidator, LettersValidator } from '../../custom/custom-validators';

@Component({
    selector: 'customer-upsert',
    templateUrl: './customer-upsert.component.html',
    styleUrls: ['./customer-upsert.component.scss']
})
export class CustomerUpsertComponent implements OnInit {

    public customerGroup!: FormGroup;

    @Input() public formData!: FormGroup;
    @Input() public isAdd!: boolean;
    @Input() public formValues!: Customer;
    @Output() public formChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
    public isValueSourceAdmin = true;
    public nameErrors = '';
    public addressTwoErrors = '';
    public postalCodeErrors = '';
    public cityErrors = '';
    public countryErrors = '';
    public addressOneErrors = '';
    public stateErrors = '';
    public phoneErrors = '';
    public partnerErrors = '';

    constructor(private fb: FormBuilder, public dataStore: DataStore) { }

    public ngOnInit(): void {
        this.customerGroup = this.fb.group({
            Id: [''],
            Name: ['', [Validators.required, LettersAndNumbersValidator]],
            AddressOne: ['', [Validators.required, LettersAndNumbersValidator]],
            AddressTwo: ['', [LettersAndNumbersValidator]],
            AddressThree: [''],
            City: ['', [Validators.required, LettersValidator, Validators.maxLength(100)]],
            State: ['', [Validators.required, LettersValidator, Validators.maxLength(100)]],
            Country: ['', [Validators.required, LettersValidator, Validators.maxLength(50)]],
            PostalCode: ['', [Validators.required, LettersAndNumbersValidator, Validators.maxLength(16)]],
            Phone: ['', [Validators.required, BusinessPhoneNumberValidator, Validators.maxLength(20)]],
            Partner: ['', Validators.required]
        });
        this.addGroupToParent();
        if (this.isAdd) {
            this.customerGroup.patchValue({
                Partner: this.dataStore.isNavigatorInternalAdmin === true ? 'Kroll' : 'ValuSource'
            });
        }
        if (!this.isAdd && this.formValues) {
            let reseller = '';
            if (this.formValues.Licenses.length > 0) {
                this.formValues.Licenses.forEach((customer) => {
                    if (customer.Reseller) {
                        reseller = customer.Reseller;
                    }
                });
            }
            this.customerGroup.setValue({
                Id: this.formValues.Id,
                Name: this.formValues.Name,
                AddressOne: this.formValues.Address ? this.formValues.Address.Line1 : '',
                AddressTwo: this.formValues.Address ? this.formValues.Address.Line2 : '',
                AddressThree: this.formValues.Address ? this.formValues.Address.Line3 : '',
                City: this.formValues.Address ? this.formValues.Address.City : '',
                State: this.formValues.Address ? this.formValues.Address.State : '',
                Country: this.formValues.Address ? this.formValues.Address.Country : '',
                PostalCode: this.formValues.Address ? this.formValues.Address.PostalCode : '',
                Phone: this.formValues.Address ? this.formValues.Address.BusinessPhone : '',
                Partner: reseller === 'Internal' ? 'Kroll' : reseller
            });

        }

        this.VerifyFormValuesOnLoad();
        this.customerGroup.valueChanges.subscribe(() => {
                this.GetPhoneErrors();
                this.GetCustomerNameErrors();
                this.GetAddressOneErrors();
                this.GetAddressTwoErrors();
                this.GetCityErrors();
                this.GetCountryErrors();
                this.GetStateErrors();
                this.GetPostalCodeErrors();
                this.GetPartnerErrors();
        });
    }

    private GetPartnerErrors(): void {
        if (this.customerGroup.controls.Partner.errors !== null && this.customerGroup.controls.Partner.touched) {
            this.PartnerErrors();
        } else if (this.customerGroup.controls.Partner.errors !== null && this.customerGroup.controls.Partner.untouched) {
            this.PartnerErrors();
        } else {
            this.partnerErrors = '';
        }
    }

    private GetPostalCodeErrors(): void {
        if (this.customerGroup.controls.PostalCode.errors !== null && this.customerGroup.controls.PostalCode.touched) {
            this.PostalCodeErrors();
        } else if (this.customerGroup.controls.PostalCode.errors !== null && this.customerGroup.controls.PostalCode.untouched) {
            this.PostalCodeErrors();
        } else {
            this.postalCodeErrors = '';
        }
    }

    private GetCountryErrors(): void {
        if (this.customerGroup.controls.Country.errors !== null && this.customerGroup.controls.Country.touched) {
            this.CountryErrors();
        } else  if (this.customerGroup.controls.Country.errors !== null && this.customerGroup.controls.Country.untouched) {
            this.CountryErrors();
        } else {
            this.countryErrors = '';
        }
    }

    private GetStateErrors(): void {
        if (this.customerGroup.controls.State.errors !== null && this.customerGroup.controls.State.touched) {
            this.StateErrors();
        } else if (this.customerGroup.controls.State.errors !== null && this.customerGroup.controls.State.untouched) {
            this.StateErrors();
        } else {
            this.stateErrors = '';
        }
    }

    private GetCityErrors(): void {
        if (this.customerGroup.controls.City.errors !== null && this.customerGroup.controls.City.touched) {
            this.CityErrors();
        } else if (this.customerGroup.controls.City.errors !== null && this.customerGroup.controls.City.untouched) {
            this.CityErrors();
        } else {
            this.cityErrors = '';
        }
    }

    private GetAddressTwoErrors(): void {
        if (this.customerGroup.controls.AddressTwo.errors !== null && this.customerGroup.controls.AddressTwo.touched) {
            this.AddressTwoErrors();
        } else if (this.customerGroup.controls.AddressTwo.errors !== null && this.customerGroup.controls.AddressTwo.untouched) {
            this.AddressTwoErrors();
        } else {
            this.addressTwoErrors = '';
        }
    }

    private GetAddressOneErrors(): void {
        if (this.customerGroup.controls.AddressOne.errors !== null && this.customerGroup.controls.AddressOne.touched) {
            this.AddressOneErrors();
        } else if (this.customerGroup.controls.AddressOne.errors !== null && this.customerGroup.controls.AddressOne.untouched) {
            this.AddressOneErrors();
        } else  {
            this.addressOneErrors = '';
        }
    }

    private GetCustomerNameErrors(): void {
        if (this.customerGroup.controls.Name.errors !== null && this.customerGroup.controls.Name.touched) {
            this.CustomerNameErrors();
        } else if (this.customerGroup.controls.Name.errors !== null && this.customerGroup.controls.Name.untouched) {
            this.CustomerNameErrors();
        } else {
            this.nameErrors = '';
        }
    }

    private GetPhoneErrors(): void {
        if (this.customerGroup.controls.Phone.errors !== null && this.customerGroup.controls.Phone.touched) {
            this.PhoneErrors();
        } else  if (this.customerGroup.controls.Phone.errors !== null && this.customerGroup.controls.Phone.untouched) {
            this.PhoneErrors();
        } else {
            this.phoneErrors = '';
        }
    }

    private PartnerErrors(): void {
        if (this.customerGroup.controls.Partner.errors !== null) {
            if (this.customerGroup.controls.Partner.errors.required) {
                this.partnerErrors = 'Partner name cannot be blank';
            }
        }
    }

    private PostalCodeErrors(): void {
        if (this.customerGroup.controls.PostalCode.errors !== null) {
            if (this.customerGroup.controls.PostalCode.errors.required) {
                this.postalCodeErrors = 'Zipcode cannot be blank';
            } else if (this.customerGroup.controls.PostalCode.errors.isLettersAndNumbersValid) {
                this.postalCodeErrors = 'Only letters and number are allowed';
            } else if (this.customerGroup.controls.PostalCode.errors.maxlength) {
                this.postalCodeErrors = 'Maximum 16 characters are allowed';
            }
        }
    }

    private CountryErrors(): void {
        if (this.customerGroup.controls.Country.errors !== null) {
            if (this.customerGroup.controls.Country.errors.required) {
                this.countryErrors = 'Country cannot be blank';
            } else if (this.customerGroup.controls.Country.errors.isLettersValid) {
                this.countryErrors = 'Only letters and number are allowed';
            } else if (this.customerGroup.controls.Country.errors.maxlength) {
                this.countryErrors = 'Maximum 50 characters are allowed';
            }
        }
    }

    private StateErrors(): void {
        if (this.customerGroup.controls.State.errors !== null) {
            if (this.customerGroup.controls.State.errors.required) {
                this.stateErrors = 'State cannot be blank';
            } else if (this.customerGroup.controls.State.errors.isLettersValid) {
                this.stateErrors = 'Only letters and number are allowed';
            } else if (this.customerGroup.controls.State.errors.maxlength) {
                this.stateErrors = 'Maximum 100 characters are allowed';
            }
        }
    }

    private CityErrors(): void {
        if (this.customerGroup.controls.City.errors !== null) {
            if (this.customerGroup.controls.City.errors.required) {
                this.cityErrors = 'City cannot be blank';
            } else if (this.customerGroup.controls.City.errors.isLettersValid) {
                this.cityErrors = 'Only letters and number are allowed';
            } else if (this.customerGroup.controls.City.errors.maxlength) {
                this.cityErrors = 'Maximum 100 characters are allowed';
            }
        }
    }

    private AddressTwoErrors(): void {
        if (this.customerGroup.controls.AddressTwo.errors !== null) {
            if (this.customerGroup.controls.AddressTwo.errors.isLettersAndNumbersValid) {
                this.addressTwoErrors = 'Only letters and number are allowed';
            }
        }
    }

    private AddressOneErrors(): void {
        if (this.customerGroup.controls.AddressOne.errors !== null) {
            if (this.customerGroup.controls.AddressOne.errors.required) {
                this.addressOneErrors = 'Address cannot be blank';
            } else if (this.customerGroup.controls.AddressOne.errors.isLettersAndNumbersValid) {
                this.addressOneErrors = 'Only letters and number are allowed';
            }
        }
    }

    private CustomerNameErrors(): void {
        if (this.customerGroup.controls.Name.errors !== null) {
            if (this.customerGroup.controls.Name.errors.required) {
                this.nameErrors = 'Customer name cannot be blank';
            } else if (this.customerGroup.controls.Name.errors.isLettersAndNumbersValid) {
                this.nameErrors = 'Only letters and number are allowed';
            }
        }
    }

    private PhoneErrors(): void {
        if (this.customerGroup.controls.Phone.errors !== null) {
            if (this.customerGroup.controls.Phone.errors.required) {
                this.phoneErrors = 'Phone number cannot be blank';
            } else if (this.customerGroup.controls.Phone.errors.isBusinessPhoneNumberValid) {
                this.phoneErrors = 'Only numbers and following characters are allowed \'-\' \'+\' \'(\'  and \')\'';
            } else if (this.customerGroup.controls.Phone.errors.maxlength) {
                this.phoneErrors = 'Maximum 20 characters are allowed';
            }
        }
    }

    private VerifyFormValuesOnLoad(): void {

        if (this.customerGroup.controls.Partner.errors !== null &&
            this.customerGroup.controls.Partner.invalid &&
            this.customerGroup.controls.Partner.untouched) {
               this.GetPartnerErrors();
            }

        if (this.customerGroup.controls.PostalCode.errors !== null &&
            this.customerGroup.controls.PostalCode.invalid &&
            this.customerGroup.controls.PostalCode.untouched) {
                this.GetPostalCodeErrors();
            }

        if (this.customerGroup.controls.Country.errors !== null &&
            this.customerGroup.controls.Country.invalid &&
            this.customerGroup.controls.Country.untouched) {
                this.GetCountryErrors();
            }

        if (this.customerGroup.controls.State.errors !== null &&
            this.customerGroup.controls.State.invalid &&
            this.customerGroup.controls.State.untouched) {
                this.GetStateErrors();
            }

        if (this.customerGroup.controls.City.errors !== null &&
            this.customerGroup.controls.City.invalid &&
            this.customerGroup.controls.City.untouched) {
                this.GetCityErrors();
            }

        if (this.customerGroup.controls.AddressTwo.errors !== null &&
            this.customerGroup.controls.AddressTwo.invalid &&
            this.customerGroup.controls.AddressTwo.untouched) {
                this.GetAddressTwoErrors();
            }

        if (this.customerGroup.controls.AddressOne.errors !== null &&
            this.customerGroup.controls.AddressOne.invalid &&
            this.customerGroup.controls.AddressOne.untouched) {
                this.GetAddressOneErrors();
            }

        if (this.customerGroup.controls.Name.errors !== null &&
            this.customerGroup.controls.Name.invalid &&
            this.customerGroup.controls.Name.untouched) {
                this.GetCustomerNameErrors();
            }

        if (this.customerGroup.controls.Phone.errors !== null &&
            this.customerGroup.controls.Phone.invalid &&
            this.customerGroup.controls.Phone.untouched) {
               this.GetPhoneErrors();
            }
    }

    private addGroupToParent(): void {
        this.formData.addControl('Customer', this.customerGroup);
        // this.onFormGroupChange.emit(this.formData);
    }

}
