import * as XLSX from 'xlsx';

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { LicenseUser } from '../../_api/responses/license-user.response';

export interface UserImportResult {
    licenseUsers: LicenseUser[];
    error?: Error;
}

@Injectable()
export class UserImportService {
    public createLicenseUsers(file: File, licenseId: string): Observable<UserImportResult> {
        const result = new Subject<UserImportResult>();

        const isCsv = file.name.indexOf('.csv') > -1;
        const isExcel = file.name.indexOf('.xls') > -1;
        const reader: FileReader = new FileReader();

        reader.onload = (readerEvent: any) => {
            const fileData: string = readerEvent.target.result;

            if (isCsv) {
                try {
                    const invites = this.createInvitesFromCsv(fileData, licenseId);
                    result.next(invites);
                } catch (error) {
                    result.error(error);
                }

            } else {
                result.error('Expected a .csv file');
            }

            result.complete();
        };
        reader.readAsBinaryString(file);

        return result;
    }

    private createInvitesFromCsv(data: string, licenseId: string): UserImportResult {
        let rows = data.split('\n')
            .map((row) => row.replace('\n', '').replace('\r', ''))
            .filter((row) => row !== '')
            .map((row) => row.split(','));

        if (rows.length <= 0 || rows[0].length < 3) {
            throw new Error('Expected 3 columns: Email, First Name, Last Name');
        }

        // TODO: Shouldn't be any
        const expectedColumns: any[] = [
            { title: 'Email Address', short: 'Email', index: null },
            { title: 'First Name', short: 'First', index: null },
            { title: 'Last Name', short: 'Last', index: null }
            // { title: 'Title', short: 'Title', index: null },
            // { title: 'Phone', short: 'Phone', index: null },
            // { title: 'Role', short: 'Role', index: null }
        ];

        for (const expected of expectedColumns) {
            expected.index = rows[0].findIndex((column) => column.indexOf(expected.short) > -1);

            if (expected.index === -1) {
                throw new Error(`Expected a column labeled: ${expected.title}`);
            }
        }

        rows = rows.splice(1, rows.length);

        const invalidEmails: string[] = [];
        const licenseUsers: LicenseUser[] = [];
        for (const row of rows) {
            try {
                const email = row[expectedColumns.find((x) => x.short === 'Email').index];
                const isValidEmail = this.validateEmail(email);
                if (isValidEmail) {
                    const licenseUser: LicenseUser = {
                        Email: email,
                        FirstName: row[expectedColumns.find((x) => x.short === 'First').index],
                        LastName: row[expectedColumns.find((x) => x.short === 'Last').index],
                        // JobTitle: row[expectedColumns.find((x) => x.short === 'Title').index],
                        // Phone: row[expectedColumns.find((x) => x.short === 'Phone').index],
                        // IsAdmin: row[expectedColumns.find((x) => x.short === 'Role').index] === 'Admin' ? true : false,
                        Claims: [],
                        Roles: [],
                        LicenseId: licenseId,
                        IsActive: true,
                        features: [],
                        IsAdmin: false,
                        Phone: '',
                        JobTitle: ''
                    };
                    licenseUsers.push(licenseUser);
                } else {
                    invalidEmails.push(email);
                }

            } catch (exception) {
                throw new Error(`An unexpected error occurred while attempting to read the following column data:<br/> ${row.join(',')}`);
            }
        }

        if (invalidEmails.length > 0) {
            return {
                licenseUsers,
                error: new Error(`Some users were not added because their emails could not be validated:<br/> ` +
                    `${invalidEmails.join('<br/>')}`)
            };
        } else {
            return { licenseUsers };
        }
    }

    // private handleExcel(data: string) {
    //     const wb: XLSX.WorkBook = XLSX.read(data, { type: 'binary' });

    //     /* grab first sheet */
    //     const wsname: string = wb.SheetNames[0];
    //     const ws: XLSX.WorkSheet = wb.Sheets[wsname];
    // }

    public validateEmail(email: string): boolean {
        // tslint:disable-next-line:max-line-length
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
}
